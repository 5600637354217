import React from 'react';
import T from 'prop-types';
import * as Classes from './styles.module.scss';
import AddButton from './add_button';

const GiftCardAddToCart = ({
  price, name, email, message, daliveryDate, senderName,
  isRequiredFieldsEmpty, addGiftCardToCart, label, adding, added
}) => {
  const doAddToCart = () => {
    if (!isRequiredFieldsEmpty()) {
      addGiftCardToCart({
        giftPrice: price,
        giftRecipientEmail: email,
        giftRecipientName: name,
        giftSenderName: senderName,
        giftDeliveryDate: daliveryDate,
        giftMessage: message
      });
    }
  };

  return (
    <div className={ Classes.bar }>
      <div className={ Classes.total } key="price">
        Price:&nbsp;
        { label }
      </div>

      <AddButton doAddToCart={ doAddToCart } adding={ adding } added={ added } />
    </div>
  );
};

GiftCardAddToCart.propTypes = {
  price: T.number,
  senderName: T.string,
  name: T.string,
  email: T.string,
  message: T.string,
  daliveryDate: T.string,
  isRequiredFieldsEmpty: T.func,
  addGiftCardToCart: T.func.isRequired,
  label: T.string.isRequired,
  adding: T.bool.isRequired,
  added: T.bool.isRequired
};

export default GiftCardAddToCart;
