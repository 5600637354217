import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import classnames from 'classnames';
import * as AppClasses from '../Application/styles.module.scss';
import Section from '../checkout/section';
import * as Classes from '../checkout/styles.module.scss';
import EmailSection from '../checkout/email_section';
import CartItems from '../cart_items';
import CheckoutSummary from '../checkout_summary';
import ShowAddress from '../addresses/show';
import PlacedOrder from './placed_order';
import GTMPageView from '../Application/data_layer';

const EditOrderPayment = ({
  loading,
  currentPaymentStatus,
  virtualOrder,
  paymentStatus,
  history
}) => {
  const { id } = useParams();

  const sections = loading ? null : (
    <PlacedOrder
      currentPaymentStatus={ currentPaymentStatus }
      paymentStatus={ paymentStatus }
      id={ id }
      virtualOrder={ virtualOrder }
    />
  );

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="checkout" section="checkout" />
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        <h1>Checkout</h1>
      </div>
      <div className={ AppClasses.gridCheckout }>
        <div className={ AppClasses.left }>
          <EmailSection />
          <Section title="SHIPPING ADDRESS" edit="/my/address/edit">
            <ShowAddress />
          </Section>
          <Section title="BILLING ADDRESS" edit="/my/address/edit?billing=true">
            <ShowAddress billing />
          </Section>
          <Section title="PLACE ORDER" edit={ `/orders/${ id }/payment/new` } editLabel="Back">
            { sections }
          </Section>
        </div>
        <div className={ AppClasses.right }>
          <Section title="SUMMARY OF CHARGES">
            <div className={ Classes.content }>
              <CheckoutSummary noRedirect history={ history } />
            </div>
          </Section>
          <Section title="SHOPPING CART">
            <div className={ Classes.content }>
              <CartItems narrow orderId={ parseInt(id, 10) } checkoutStep={ 4 } />
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};

EditOrderPayment.propTypes = {
  loading: PropTypes.bool.isRequired,
  currentPaymentStatus: PropTypes.string,
  paymentStatus: PropTypes.string,
  virtualOrder: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired
};

export default EditOrderPayment;
