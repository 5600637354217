window.jQuery(() => {
  window.jQuery(document).on('click', 'a.have_account', (e) => {
    const m = window.jQuery(e.target).parents('.modal');
    m.find('.sign-in, .sign-up').toggleClass('hidden');
    m.find('input:visible:first').focus();
    return false;
  });

  window.jQuery('.sign-in form').on('ajax:before', () => {
    window.jQuery('.sign-in form .wrong-password').hide();
    return true;
  });

  window.jQuery('.sign-in form').on('ajax:error', (event) => {
    const [_, err, xhr] = event.detail;
    if (xhr.status === 401) {
      window.jQuery('.sign-in form .wrong-password').show();
    }
  });

  window.jQuery('.sign_in_up').on('shown', (e) => {
    const parent = window.jQuery(e.target);
    const defaultTab = parent.data('default');
    parent.data('default', null);
    if (defaultTab) {
      parent.find('.sign-in, .sign-up').addClass('hidden');
      if (defaultTab === 'signin') {
        parent.find('.sign-in').removeClass('hidden');
      } else {
        parent.find('.sign-up').removeClass('hidden');
      }
    }
    parent.find('input:visible:first').focus();
  });

  window.jQuery(window).on('after:auth', () => {
    if (window.after_auth) { window.after_auth(); }
    window.after_auth = null;
  });

  const signin = (e) => {
    e.preventDefault();
    window.jQuery('.fav_details').hide();
    window.jQuery('#sign_in_up').data('default', 'signin');
    window.jQuery('#sign_in_up').modal();
    return false;
  };

  const showSignupPopup = (e) => {
    e.preventDefault();
    window.jQuery('#sign_in_up').data('default', 'signup');
    window.jQuery('#sign_in_up').modal();
    return false;
  };

  const signup = (e) => {
    e.preventDefault();
    window.jQuery('.fav_details').hide();
    return showSignupPopup(e);
  };

  const signupWithIcon = (e) => {
    e.preventDefault();
    window.jQuery('.fav_details').show();
    return showSignupPopup(e);
  };

  document.addEventListener('LoginRequired', signup);
  document.addEventListener('LoginRequiredFavroutes', signupWithIcon);
  document.addEventListener('SignIn', signin);

  window.jQuery(document).on('click', 'a.signin-link', signin);
  window.jQuery(document).on('click', 'a.signup-link', signup);
});
