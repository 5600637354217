import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import WallSingle from '../wall_single/wall_single';
import Prev from './prev';
import Next from '../walls_as_page/next';
import * as Classes from '../walls/styles.module.scss';
import { scheduleProductView } from '../../config/api';
import { onWallImpression } from '../../config/analytics';

const PREFETCH_ITEMS = 5;

const Carousel = ({
  items, onLoad, loading, params, history, currentIndex, addWallToCart, addWallPicturesToCart
}) => {
  const [renderIndex, setRenderIndex] = useState(1);
  const [moving, setMoving] = useState(false);

  const resetToActive = (index) => {
    scheduleProductView(onWallImpression(items[index]));
    setMoving(false);
    setRenderIndex(1);
    if (!loading && currentIndex + PREFETCH_ITEMS >= items.length) {
      onLoad();
    }
    history.push(`${ items[index].href }?${ queryString.stringify({ ...params }) }`);
  };

  const onPrev = () => {
    setMoving(true);
    setRenderIndex(renderIndex - 1);
    setTimeout(() => resetToActive(currentIndex - 1), 501);
  };

  const onNext = () => {
    setMoving(true);
    setRenderIndex(renderIndex + 1);
    setTimeout(() => resetToActive(currentIndex + 1), 501);
  };

  useEffect(() => {
    onLoad();
    scheduleProductView(onWallImpression(items && items[0]));
  }, []);

  const renderItems = () => {
    const walls = [];
    if (currentIndex === 0) {
      walls.push((
        <div key="l">
          <div className={ Classes.left } />
        </div>
      ));
    } else {
      walls.push((
        <div key={ items[currentIndex - 1].wallId }>
          <WallSingle
            addWallToCart={ addWallToCart }
            addWallPicturesToCart={ addWallPicturesToCart }
            skipBreadCrumbs
            { ...items[currentIndex - 1] }
          />
        </div>
      ));
    }
    walls.push((
      <div key={ items[currentIndex].wallId }>
        <WallSingle
          addWallToCart={ addWallToCart }
          addWallPicturesToCart={ addWallPicturesToCart }
          skipBreadCrumbs
          { ...items[currentIndex] }
        />
      </div>
    ));
    if (currentIndex + 1 < items.length) {
      walls.push((
        <div key={ items[currentIndex + 1].wallId }>
          <WallSingle
            addWallToCart={ addWallToCart }
            addWallPicturesToCart={ addWallPicturesToCart }
            skipBreadCrumbs
            { ...items[currentIndex + 1] }
          />
        </div>
      ));
    }
    return walls;
  };

  const prev = (currentIndex === 0 ? null : (
    <button type="button" className={ Classes.prev } onClick={ onPrev } aria-label="Prev">
      <Prev />
    </button>
  ));

  const next = (currentIndex + 1 >= items.length ? null : (
    <button type="button" className={ Classes.next } onClick={ onNext } aria-label="Next">
      <Next />
    </button>
  ));

  const innerStyle = {
    transform: `translate3d(-${ renderIndex * 100 }%, 0, 0)`
  };

  return (
    <div className={ Classes.carousel }>
      <div className={ Classes.keeper }>
        <div
          className={ classnames(Classes.carouselInner, { [Classes.moving]: moving }) }
          style={ innerStyle }
        >
          { items.length ? renderItems() : null }
        </div>
      </div>
      { prev }
      { next }
    </div>
  );
};

Carousel.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onLoad: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  addWallToCart: PropTypes.func.isRequired,
  addWallPicturesToCart: PropTypes.func.isRequired
};

export default Carousel;
