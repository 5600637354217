import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as Classes from '../WorkListItem/styles.module.scss';

const Item = ({
  name,
  imageUrl,
  path,
  className,
  children,
  direct
}) => {
  const img = (
    <img
      alt={ name }
      src={ imageUrl }
    />
  );
  const content = direct ? (
    <a href={ path } className={ Classes.keeper }>
      { img }
    </a>
  ) : (
    <Link to={ path } className={ Classes.keeper }>
      { img }
    </Link>
  );
  return (
    <li className={ classnames(Classes.root, className) }>
      <div className={ Classes.anchor }>
        { content }
      </div>
      <div className={ classnames(Classes.infoBase, Classes.favorite) }>
        { children }
      </div>
    </li>
  );
};

Item.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  direct: PropTypes.bool
};

export default Item;
