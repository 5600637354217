import React from 'react';
import PropTypes from 'prop-types';
import {
  useParams
} from 'react-router-dom';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as AppClasses from '../Application/styles.module.scss';
import Section from '../checkout/section';
import * as Classes from '../checkout/styles.module.scss';
import EmailSection from '../checkout/email_section';
import CartItems from '../cart_items';
import CheckoutSummary from '../checkout_summary';
import ShowAddress from '../addresses/show';
import GTMPageView from '../Application/data_layer';
import Incompatible from './incompatible';

const EditOrder = ({ calculatingShipping, history, incompatible }) => {
  const { id } = useParams();

  const polling = !!calculatingShipping;

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="checkout" section="checkout" />
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        <h1>Checkout</h1>
      </div>
      <div className={ AppClasses.gridCheckout }>
        <div className={ AppClasses.left }>
          <EmailSection />
          <Section title="SHIPPING ADDRESS" edit="/my/address/edit">
            <ShowAddress />
          </Section>
          <Section title="BILLING ADDRESS" edit="/my/address/edit?billing=true">
            <ShowAddress billing />
          </Section>
          <Section title="PLACE ORDER">
            <Incompatible incompatible={ incompatible } id={ id } />
          </Section>
        </div>
        <div className={ AppClasses.right }>
          <Section title="SUMMARY OF CHARGES">
            <div className={ Classes.content }>
              <CheckoutSummary history={ history } />
            </div>
          </Section>
          <Section title="SHOPPING CART" edit="/cart_items">
            <div className={ Classes.content }>
              <CartItems
                narrow
                orderId={ parseInt(id, 10) }
                polling={ polling }
                checkoutStep={ 3 }
              />
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};

EditOrder.propTypes = {
  calculatingShipping: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
  incompatible: PropTypes.bool
};

export default EditOrder;
