import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const WorkFilter = ({
  fetchWork,
  // parent
  onClick,
  className,
  workId,
  // reducer
  title,
  authorName,
  id
}) => {
  useEffect(() => {
    if (id !== workId) { fetchWork(workId); }
  }, [workId]);

  if (id === workId && title) {
    return (
      <button className={ className } onClick={ onClick } type="button">
        { title }
        &nbsp;by&nbsp;
        { authorName }
        &nbsp;
        &times;
      </button>
    );
  }
  return null;
};

WorkFilter.propTypes = {
  fetchWork: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  workId: PropTypes.number.isRequired,
  title: PropTypes.string,
  authorName: PropTypes.string,
  id: PropTypes.number
};

export default WorkFilter;
