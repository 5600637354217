window.jQuery(() => {
  window.jQuery(document).on('mouseenter', '.rows.collection a.boutique-item', (e) => {
    const o = window.jQuery(e.currentTarget);
    const root = o.parents('.rows.collection');
    const { image, name, klass } = o.data();
    if (!image) return;

    const href = o.attr('href');
    const i = root.find('a.current_pick img');
    i.hide();

    if (klass) {
      i.attr('class', klass);
    } else {
      i.removeAttr('class');
    }

    root.find('a.current_pick').attr('href', href);
    i.attr('src', image);
    root.find('p.pick-name').html(name);
    i.show();
  });
});
