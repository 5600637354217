/* eslint-disable max-len */
/* eslint-disable import/no-webpack-loader-syntax */
import 'expose-loader?exposes=$&exposes=jQuery!jquery';

import Rails from '@rails/ujs';

require('../pipeline/application_assets/application.sass');
require('../../../vendor/legacy/cloudzoom');
require('../../../vendor/legacy/customEventPolyfill');
require('imports-loader?additionalCode=var%20define%20=%20false;var%20exports=false;!../../../vendor/bootstrap/javascripts/bootstrap-transition');
require('imports-loader?additionalCode=var%20define%20=%20false;var%20exports=false;!../../../vendor/bootstrap/javascripts/bootstrap-collapse');
require('imports-loader?additionalCode=var%20define%20=%20false;var%20exports=false;!../../../vendor/legacy/bootstrap-modalmanager');
require('imports-loader?additionalCode=var%20define%20=%20false;var%20exports=false;!../../../vendor/bootstrap/javascripts/bootstrap-dropdown');
require('imports-loader?additionalCode=var%20define%20=%20false;var%20exports=false;!../../../vendor/legacy/bootstrap-modal');
require('imports-loader?additionalCode=var%20define%20=%20false;var%20exports=false;!../../../vendor/legacy/bootstrap-datepicker');
require('imports-loader?additionalCode=var%20define%20=%20false;var%20exports=false;!jquery_nested_form');

require.context('../pipeline/application_assets/images', true);

require('../pipeline/application/application.js');

Rails.start();
