import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as classes from './classes.module.scss';

const PaymentMethod = ({
  method,
  title,
  currentMethod,
  children,
  setMethod,
  onSelected
}) => {
  const isSelected = currentMethod === method;
  useEffect(() => {
    if (isSelected && onSelected) {
      onSelected();
    }
  }, [isSelected]);
  const details = isSelected ? children : null;
  const onChange = () => setMethod(method);
  const id = `payment_method_${ method }`;
  return (
    <div className={ classes.method }>
      <label htmlFor={ id }>
        <input
          type="radio"
          name="payment_method"
          value={ method }
          checked={ isSelected }
          onChange={ onChange }
          id={ id }
        />
        { title }
      </label>
      { details }
    </div>
  );
};

PaymentMethod.propTypes = {
  method: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  currentMethod: PropTypes.string,
  children: PropTypes.node,
  setMethod: PropTypes.func.isRequired,
  onSelected: PropTypes.func
};

export default PaymentMethod;
