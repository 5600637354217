require('./login.js');
require('./picks.js');

window.CloudZoom.quickStart();

window.jQuery(() => {
  const $modal = window.jQuery('#ajax-modal');
  window.jQuery(document).on('click', 'a.ajax', (e) => {
    e.preventDefault();

    setTimeout(() => {
      $modal.load(window.jQuery(e.target).attr('href'), '', () => {
        $modal.modal();
      });
    }, 1000);
  });

  setTimeout(() => {
    window.jQuery('.alert.alert-info').fadeOut();
  }, 8000);

  window.jQuery('.paypal_email_form').click((e) => {
    e.preventDefault();
    window.jQuery('#edit_user').toggle();
    return false;
  });

  window.jQuery('.scroll-to-top').click(() => {
    window.jQuery('html, body').animate({ scrollTop: 0 });
    return false;
  });

  window.jQuery('.nav.top-menu li > a').on('touchend', (e) => {
    const li = window.jQuery(e.currentTarget).parents('li:first');

    if (li.hasClass('touched')) return true;

    window.jQuery('.nav.top-menu li').removeClass('touched');
    li.addClass('touched');
    e.preventDefault();
    e.stopPropagation();
    return false;
  });

  window.jQuery(document).on('touchend', (e) => {
    if (!window.jQuery(e.target).parents('.nav.top-menu').length) {
      window.jQuery('.nav.top-menu li').removeClass('touched');
    }
  });

  const onScroll = () => {
    if (window.jQuery(window).scrollTop() > 0) {
      window.jQuery('.scroll-to-top').addClass('shown');
    } else {
      window.jQuery('.scroll-to-top').removeClass('shown');
    }
  };
  window.jQuery(window).scroll(onScroll);

  window.jQuery('.promo-popup').on('shown', (e) => {
    e.preventDefault();
    window.jQuery('.promo-banner').css('visibility', 'hidden');
  });

  window.jQuery('.promo-popup').on('hidden', (e) => {
    e.preventDefault();
    window.jQuery('.promo-banner').css('visibility', 'visible');
  });

  window.jQuery('.mobile-expand').on('click', (e) => {
    e.preventDefault();
    window.jQuery(e.currentTarget).toggleClass('expanded');
  });

  window.jQuery('#menuc').on('change', (e) => {
    e.preventDefault();
    window.jQuery('body')[0].style.overflow = window.jQuery('#menuc').prop('checked') ? 'hidden' : '';
  });

  window.jQuery('.nav-keeper').on('click', (e) => {
    if (window.jQuery(e.target).hasClass('nav-keeper')) {
      e.preventDefault();
      window.jQuery('#menuc').prop('checked', false);
    }
  });
});
