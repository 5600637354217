import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sharing from './sharing';
import * as Classes from './styles.module.scss';
import Rearrange from './rearrange';
import WallPreview from '../walls_as_grid/wall_preview';
import ZoomModal from '../Viewer/zoom_modal';
import Preview from './preview';
import ScaleIcon from '../Viewer/scale_icon.js';

const ToolBar = ({
  id, href, description, imageUrl, wallPictures, width, height, currentColor, noFavorites
}) => {
  const [displayEnlarge, setDisplayEnlarge] = useState(false);
  const showEnlarge = () => setDisplayEnlarge(true);
  const hideEnlarge = () => setDisplayEnlarge(false);
  const [displayPreview, setDisplayPreview] = useState(false);
  const showPreview = () => setDisplayPreview(true);
  const hidePreview = () => setDisplayPreview(false);

  const enlarge = displayEnlarge ? (
    <ZoomModal
      img={ imageUrl }
      onHide={ hideEnlarge }
    />
  ) : null;

  const preview = displayPreview ? (
    <WallPreview onCancel={ hidePreview }>
      <div className={ Classes.column1 }>
        <Preview
          className={ Classes.zoomIn }
          wallPictures={ wallPictures }
          previewX={ 0 }
          previewY={ 0 }
          previewWidth={ width }
          previewHeight={ height }
          currentColor={ currentColor }
          skipWallPictures={ {} }
          wallId={ id }
          withSilhouette
        />
        <div />
      </div>
    </WallPreview>
  ) : null;

  const enlargeButton = imageUrl ? (
    <button className={ classnames(Classes.tool, Classes.mobileHidden) } type="button" title="Enlarge" onClick={ showEnlarge }>
      <i className="icon-fullscreen" />
      Enlarge
    </button>
  ) : null;

  return (
    <>
      { enlarge }
      { preview }
      <div className={ Classes.keeper }>
        <div className={ classnames(Classes.column1, Classes.toolbar) }>
          <div className={ classnames(Classes.keeper, Classes.left) }>
            <a
              href={ `/stylist/wall_builder/new?source_wall_id=${ id }` }
              className={ classnames(Classes.tool, Classes.mobileHidden) }
            >
              <Rearrange />
              Rearrange Wall
            </a>
            <button className={ Classes.tool } type="button" title="Zoom In" onClick={ showPreview }>
              <ScaleIcon />
              Show to scale
            </button>
            { enlargeButton }
          </div>
          <div className={ classnames(Classes.keeper, Classes.sharing) }>
            <Sharing
              href={ href }
              description={ description }
              imageUrl={ imageUrl }
              id={ id }
              noFavorites={ noFavorites }
            />
          </div>
        </div>
      </div>
    </>
  );
};

ToolBar.propTypes = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  wallPictures: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  currentColor: PropTypes.string,
  noFavorites: PropTypes.bool.isRequired
};

export default ToolBar;
