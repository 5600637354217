import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';

const PUBLISHED = 'published';
const APPROVED = 'approved';

const Wall = ({
  id, name, imageUrl, wallPicturesCount, status, deleteMyWall, isDeleting
}) => {
  const href = `${ window.currentLocale.basename }/stylist/wall_builder?id=${ id }`;

  const img = imageUrl ? (
    <a href={ href }>
      <img src={ imageUrl } alt={ name } />
    </a>
  ) : (
    <div>
      <a href={ href } className={ Classes.edit }>
        VIEW/EDIT WALL
      </a>
    </div>
  );

  const sent = status === PUBLISHED || status === APPROVED;

  const statusKlass = classnames(Classes.status, {
    [Classes.published]: sent
  });

  const onRemove = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure?')) {
      deleteMyWall(id);
    }
  };

  let remove = null;
  if (isDeleting) {
    remove = <span>Removing wall, hold on...</span>;
  } else if (!sent) {
    remove = (
      <button type="button" className={ Classes.remove } onClick={ onRemove }>
        Remove
      </button>
    );
  }

  return (
    <li>
      <div>
        { img }
      </div>
      <div className={ Classes.name }>
        <a href={ href }>
          <h4>{ name }</h4>
        </a>
        <span>
          { wallPicturesCount }
          &nbsp;
          wall picture(s)
        </span>
        <span>
          #
          { id }
        </span>
      </div>
      <div className={ statusKlass }>
        { status }
        { remove }
      </div>
    </li>
  );
};

Wall.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  wallPicturesCount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  deleteMyWall: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired
};

export default Wall;
