import React from 'react';
import T from 'prop-types';
import * as Classes from './styles.module.scss';

const Delimiter = ({ notop }) => (
  <div className={ notop ? Classes.notop : Classes.root } />
);

Delimiter.propTypes = {
  notop: T.bool
};

export default Delimiter;
