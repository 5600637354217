import React from 'react';
import PropTypes from 'prop-types';
import PinIt from 'arts/components/Social/pin_it';
import classNames from 'classnames';
import ArrowDown from '../../assets/arrow_down';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import Favorite from '../favorite';
import BreadCrumbsGeneric, { BreadCrumbsSkeleton } from '../bread_crumbs';

const renderImage = (url, alt) => {
  if (!url) { return null; }
  return (
    <img src={ url } alt={ alt } />
  );
};

const bcLinks = (authorName, authorId, authorSlug) => [
  { text: 'Artists', to: '/artists' },
  {
    text: authorName, to : `/artists/${ authorId }/${ authorSlug }`
  }
];

const location = (city, country) => {
  if (!city && !country) { return null; }
  return (
    <span>
      { city ? `${ city }, ` : null }
      { country }
    </span>
  );
};

export const ArtistTitleSkeleton = () => (
  <div className={ Classes.author } aria-busy="true">
    <BreadCrumbsSkeleton />
    <div className={ classNames(Classes.artistName, AppClasses.skeleton) } key="at">
      &nbsp;
      <div className={ Classes.middle }>
        <div>
          <h1>
            &nbsp;
          </h1>
          <span>&nbsp;</span>
        </div>
      </div>
    </div>
  </div>
);

const ArtistTitle = ({
  name, avatarUrl, authorId, authorSlug, skipBreadcrumbs, skipShare, city, country, children
}) => (
  <div className={ Classes.author }>
    {
      skipBreadcrumbs ? null : (
        <BreadCrumbsGeneric
          links={ bcLinks(name, authorId, authorSlug) }
        />
      )
    }
    <div className={ Classes.artistName } key="at">
      { renderImage(avatarUrl, name) }
      <div className={ Classes.middle }>
        <div>
          <h1>
            { name }
          </h1>
          { location(city, country) }
        </div>
        <div className={ Classes.right }>
          {
            skipShare ? null : (
              <div className={ Classes.share }>
                <Favorite id={ parseInt(authorId, 10) } type="User" />
                <PinIt
                  href={ window.location.href }
                  imageUrl={ avatarUrl }
                  description={ `${ name } on Artfully Walls` }
                />
              </div>
            )
          }
          {
            skipBreadcrumbs ? null : (
              <div className={ Classes.profile }>
                <a href="#profile">
                  View Artist Profile
                </a>
                <ArrowDown />
              </div>
            )
          }
          { children }
        </div>
      </div>
    </div>
  </div>
);

ArtistTitle.propTypes = {
  name: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  authorId: PropTypes.number,
  authorSlug: PropTypes.string,
  skipBreadcrumbs: PropTypes.bool,
  skipShare: PropTypes.bool,
  city: PropTypes.string,
  country: PropTypes.string,
  children: PropTypes.node
};

export default ArtistTitle;
