// extracted by mini-css-extract-plugin
var _1 = "yaJ_kvSBbHOnXeiOClHw";
var _2 = "efHi1Gr_uE2HG9gOIHnk";
var _3 = "lBKCaVyIxxuKVcoh7jFv";
var _4 = "EanWV_9iQijJDmGM3ho8";
var _5 = "vNGAAnpzsD9xZ58OMbHJ";
var _6 = "BDDFfUS1YmEu7lUGX4ZV";
var _7 = "_IFiMN3b3oeGCDbhRT2x";
var _8 = "CaY73718EayeYlUuvjPJ";
var _9 = "uyChkfr_WyOjGeW995_H";
var _a = "R5NtD4GVfGfmnljf4t_F";
var _b = "BpuGR8WSQNwVzFK67GZc";
export { _1 as "coll2", _2 as "description", _3 as "divider", _4 as "img", _5 as "imgD", _6 as "imgM", _7 as "item", _8 as "itemText", _9 as "noCenter", _a as "wallC0", _b as "wallC1" }
