import React, { PureComponent } from 'react';
import T from 'prop-types';
// import Zoom from 'arts/components/Viewer/zoom';
// import Entry from 'arts/components/Viewer/entry';
import triosHeader from 'arts/assets/trios_header.jpg';
import {
  productShape
} from 'arts/reducers/product';
import Loader from 'arts/components/Loader';

import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import ProductsListItem from './productsListItem';
import GTMPageView from '../Application/data_layer';

class ProductsList extends PureComponent {
  static propTypes = {
    addProductToCart: T.func.isRequired,
    fetchProductsList: T.func.isRequired,
    data: T.arrayOf(productShape).isRequired,
    loading: T.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.renderList = this.renderList.bind(this);
  }

  componentDidMount() {
    this.props.fetchProductsList();
  }

  renderList() {
    const { addProductToCart } = this.props;
    if (this.props.data.length > 0) {
      return (
        <div className={ Classes.container }>
          { this.props.data.map((product) => (
            <ProductsListItem
              key={ product.id }
              data={ product }
              addProductToCart={ addProductToCart }
            />
          )) }
        </div>
      );
    }
    return (
      <div className={ Classes.containerEmpty }>
        There are no available trios.
      </div>
    );
  }

  render() {
    return (
      <div>
        <GTMPageView pageType="category" section="products" />
        <div className={ AppClasses.container }>
          <img src={ triosHeader } alt="Trios" className={ Classes.imageTop } />
          {
            this.props.loading
              ? <Loader text="Loading items, hold on for a sec..." style={ { paddingTop: 60 } } />
              : this.renderList()
          }
        </div>
      </div>
    );
  }
}

export default ProductsList;
