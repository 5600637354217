import React, { Suspense } from 'react';
import { Loader } from 'semantic-ui-react';
import * as AppClasses from '../Application/styles.module.scss';

const Giveaway = React.lazy(() => import('./giveaway'));

const AlignedLoader = () => (
  <div className={ AppClasses.innerContainerArt }>
    <Loader active inline />
  </div>
);

export default () => (
  <Suspense fallback={ <AlignedLoader /> }>
    <Giveaway />
  </Suspense>
);
