/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Uk from './uk';
import Us from './us';
import * as Classes from './styles.module.scss';
import { SET_LOCALE } from './index';

export default () => {
  const [site, Img] = window.currentLocale.currentLocale === 'uk'
    ? ['UK', Uk]
    : ['US', Us];

  const onClick = (e) => {
    e.preventDefault();
    document.dispatchEvent(new CustomEvent(SET_LOCALE));
  };

  return (
    <a href="#" onClick={ onClick } className={ Classes.site }>
      <Img />
      { site }&nbsp;
      site
    </a>
  );
};
