import React, { PureComponent } from 'react';
import T from 'prop-types';
import Zoom from 'arts/components/Viewer/zoom';
import Entry from 'arts/components/Viewer/entry';
import {
  productShape
} from 'arts/reducers/product';
import Delimiter from 'arts/components/Delimiter';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import Details from './details';
import ControlsProductImage from './controls';
import BreadCrumbsTrios from './bread_crumbs';
import Scroll from '../Application/scroll';
import GTMPageView from '../Application/data_layer';

class Product extends PureComponent {
  static propTypes = {
    fetchProduct: T.func.isRequired,
    addProductToCart: T.func.isRequired,
    match: T.shape({
      params: T.shape({
        id: T.string.isRequired
      }).isRequired
    }).isRequired,
    data: productShape.isRequired
  }

  constructor(props) {
    super(props);

    this.state = { selectedIndex: 0 };
    this.selectThumb = this.selectThumb.bind(this);
    this.renderThumbnail = this.renderThumbnail.bind(this);
  }

  componentDidMount() {
    const {
      id
    } = this.props.match.params;
    this.props.fetchProduct(id);
  }

  componentDidUpdate(prevProps) {
    const newId = this.props.match.params.id;
    if (newId !== prevProps.match.params.id) {
      this.selectThumb(0);
      this.props.fetchProduct(newId);
    }
  }

  selectThumb(index) {
    this.setState({ selectedIndex: index });
  }

  renderThumbnail({ thumbUrl }, i) {
    return (
      <Entry
        url={ thumbUrl }
        index={ i }
        key={ i }
        selectedIndex={ this.state.selectedIndex }
        onClick={ this.selectThumb }
        className={ Classes.image }
      />
    );
  }

  render() {
    const {
      data: {
        title, user, description, sections, id, retailPrice, shippingPrice
      }, addProductToCart
    } = this.props;

    if (!this.props.data.images[0]) {
      return null;
    }

    const detailsProps = {
      title, user, description, sections, id, retailPrice, shippingPrice, addProductToCart
    };

    const { imageUrl } = this.props.data.images[this.state.selectedIndex];

    // TODO: add a slider here
    const slider = this.props.data.images.map(this.renderThumbnail);

    return (
      <div className={ AppClasses.container }>
        <Scroll />
        { id ? <GTMPageView pageType="product" section="products" key={ id } /> : null }
        <BreadCrumbsTrios id={ id } title={ title } />
        <div className={ Classes.root }>
          <div className={ Classes.productViewRoot }>
            <div className={ Classes.controls }>
              <div className={ Classes.previews }>
                <Zoom
                  zoomPreviewUrl={ imageUrl }
                  klass={ `${ Classes.paper }` }
                >
                  <img
                    src={ imageUrl }
                    alt={ title }
                  />
                </Zoom>
                <ControlsProductImage zoomPreviewUrl={ imageUrl } />
              </div>
              <div className={ Classes.rollOver }>
                Rollover to zoom
              </div>
            </div>

            <div className={ Classes.thumbnails }>
              { slider }
            </div>
            <div className={ Classes.mayAlsoLike }>
              <Delimiter />
            </div>
          </div>
          <Details { ...detailsProps } />
        </div>
      </div>
    );
  }
}

export default Product;
