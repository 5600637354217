// extracted by mini-css-extract-plugin
var _1 = "u9SU7ibG4WXZq92_kzDB";
var _2 = "MxuPliFyfJUGy4jeywkA";
var _3 = "P_IbD3sSJNWgGFJILL61";
var _4 = "Tll5blad2VEUv2jsW4lu";
var _5 = "teLS6VEpAJBAJBVYcprC";
var _6 = "nsXbSi5RysXNUfjnMmw3";
var _7 = "FNsgw2E6fcf51nCX8BPD";
var _8 = "AvPsOsr6I6ZVTSoIPxcC";
var _9 = "k9w8c1cNSXEH55jdHd8j";
export { _1 as "create", _2 as "edit", _3 as "grid", _4 as "header", _5 as "name", _6 as "published", _7 as "remove", _8 as "status", _9 as "title" }
