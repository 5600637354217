import React, { useState } from 'react';
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../checkout/styles.module.scss';

const VirtualOrder = () => {
  const [isSubmitting, setSubmitting] = useState(false);

  const placeOrder = () => {
    setSubmitting(true);
    document.getElementById('place_virtual_order').submit();
  };

  return isSubmitting ? (
    <Loader active inline />
  ) : (
    <button
      type="submit"
      className={ classnames(AppClasses.button, Classes.button) }
      onClick={ placeOrder }
    >
      Place Order
    </button>
  );
};

export default VirtualOrder;
