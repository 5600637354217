/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import queryString from 'query-string';
import classnames from 'classnames';
import * as AppClasses from './styles.module.scss';
import * as Classes from './search.module.scss';
import { THUMB } from '../walls/grid_selection';

const WALLS = 'walls';
const WORKS = 'art-prints';

const OPTIONS = [
  { key: WORKS, text: 'Art', value: WORKS },
  { key: WALLS, text: 'Walls', value: WALLS }
];

const SearchBar = ({ /* history */ isToggable }) => {
  const { query } = queryString.parse(window.location.search);
  const [kind, setKind] = useState(window.location.pathname.match(/^(\/walls|\/uk\/walls)/) ? WALLS : WORKS);
  const [term, setTerm] = useState(query);
  const ref = createRef(null);

  const body = document.getElementsByTagName('body')[0];

  const onToggle = (e) => {
    e.preventDefault();
    body.classList.toggle('search-visible');
    if (ref.current) { ref.current.focus(); }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newQuery = term ? term.trim() : '';

    if (!newQuery) {
      return;
    }

    const params = { query: newQuery };
    if (kind === WALLS) {
      params.as = THUMB;
    }
    // history.push();
    window.location.href = `${ window.currentLocale.basename }/${ kind }?${ queryString.stringify(params) }`;
  };

  const onChangeKind = (event, { value }) => setKind(value);

  const onChangeTerm = (event, { value }) => setTerm(value);

  const form = (
    <form onSubmit={ onSubmit }>
      <Input
        labelPosition="left"
        aria-label="Search"
        placeholder="Search Artfully Walls"
        defaultValue={ term }
        onChange={ onChangeTerm }
        ref={ ref }
        label={
          <Dropdown defaultValue={ kind } options={ OPTIONS } onChange={ onChangeKind } aria-label="Select" />
        }
        action={ { icon: 'search', 'aria-label': 'Search' } }
      />
      {
        isToggable ? (
          <button className={ Classes.cancel } type="button" onClick={ onToggle }>&times;</button>
        ) : null
      }
    </form>
  );

  if (isToggable) {
    return (
      <>
        <a href="#" onClick={ onToggle } alt="Search">
          <i className="icon-search" />
        </a>
        <div className={ classnames(AppClasses.container20, Classes.searchContainer) }>
          <div className={ classnames(AppClasses.innerContainer20, Classes.search) }>
            { form }
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={ classnames(Classes.search, Classes.permSearch) }>
      { form }
    </div>
  );
};

SearchBar.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  isToggable: PropTypes.bool
};

export default SearchBar;
