// extracted by mini-css-extract-plugin
var _1 = "GnTxdLjaEAveUAPAK3Vg";
var _2 = "fHvf_erHDz6DUBG_TlfU";
var _3 = "SHYWF0Nt0E1U9D9FgA7L";
var _4 = "ZH_xP52rJyit3BsoOKMV";
var _5 = "hwtBvA5Gzgvnx6eDE27P";
var _6 = "Uctz7l38jJxS11gPk0E4";
var _7 = "wiVGqipIp7M28JdA_07g";
var _8 = "tGyt_x2fGxoqlBqCw7g1";
export { _1 as "description", _2 as "header", _3 as "headerText", _4 as "imgD", _5 as "imgM", _6 as "imgMHolder", _7 as "showLess", _8 as "showMore" }
