import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from 'arts/components/Loader';
import Collection from './collection';
import * as AppClasses from '../Application/styles.module.scss';
import { eGiftCards } from '../gift_cards/gift_cards';
import * as Classes from './styles.module.scss';
import GTMPageView from '../Application/data_layer';
import { trackEvent } from '../../config/analytics';

const Collections = ({
  title,
  loading,
  items,
  loadCollections,
  withGiftCard
}) => {
  useEffect(() => {
    if (items.length === 0 && !loading) {
      loadCollections();
    }
  }, []);

  useEffect(() => {
    if (!loading && items.length) {
      trackEvent('collections center', { 'collections count': items.length, title });
    }
  }, [loading, items.length]);

  const giftCard = withGiftCard ? (
    <>
      <h1>Gifts</h1>
      <Link to="/gift_cards">
        <img src="https://assets.artfullywalls.com/assets/gift_guides_banner-2024.jpg" alt="Gift Cards" className={ Classes.giftsHeader } />
      </Link>
      <ul className={ classnames(Classes.grid, AppClasses.gridContainer2inRow) }>
        <Collection
          name={ eGiftCards().title }
          description={ eGiftCards().text }
          actionLabel={ eGiftCards().link.name }
          collectionHref="/gift_cards"
        />
        <li className={ Classes.empty } />
      </ul>
    </>
  ) : null;

  const actionLabel = giftCard ? 'SHOP NOW' : 'VIEW COLLECTION';

  const collection = loading ? (
    <Loader text="Loading, hold on for a sec..." />
  ) : (
    <>
      <h1>{ items.length ? title : null }</h1>
      <ul className={ classnames(Classes.grid, AppClasses.gridContainer2inRow) }>
        {
          items.map((i) => <Collection { ...i } key={ i.id } actionLabel={ actionLabel } />)
        }
      </ul>
    </>
  );

  return (
    <div className={ AppClasses.container20 }>
      { title ? <GTMPageView pageType="category" section={ title.toLowerCase() } key={ title.toLowerCase() } /> : null }
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        { giftCard }
        { collection }
      </div>
    </div>
  );
};

Collections.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  loadCollections: PropTypes.func.isRequired,
  withGiftCard: PropTypes.bool
};

export default Collections;
