import React from 'react';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';

const renderEdit = (edit, editLabel) => {
  if (!edit) {
    return null;
  }
  return <a href={ edit }>{ editLabel || 'Edit' }</a>;
};

const Section = ({
  title, children, edit, editLabel
}) => (
  <div className={ Classes.section }>
    <h2>
      { title }
      { renderEdit(edit, editLabel) }
    </h2>
    { children }
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  edit: PropTypes.string,
  editLabel: PropTypes.string
};

export default Section;
