import React from 'react';
import PropTypes from 'prop-types';
import * as Classes from '../checkout/styles.module.scss';

const Unsubscribe = ({ checked, onChange }) => ((
  window.currentLocale.currentLocale !== 'en' && !window.skipUSL
) ? (
  <label className={ Classes.unsubscribe } htmlFor="unsubscribe">
    <input
      type="checkbox"
      id="unsubscribe"
      name="unsubscribe"
      checked={ checked }
      onChange={ onChange }
    />
    <span>
      Please tick this box if you&nbsp;
      <strong>do not</strong>
      &nbsp;want to hear from us about new arrivals, inspirations and our latest offers.
    </span>
  </label>
  ) : null);

Unsubscribe.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Unsubscribe;
