import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import { useWindowSize } from '../visual_search/use_window_size';
import * as Classes from '../works/styles.module.scss';
import ImagePreview from './image_preview';

const { w1 } = Classes;
const mobileBreak = parseInt(w1, 10);

const ImagePreviewSwitcher = ({ secondPreviewUrl, otherImageUrl, ...props }) => {
  const { width } = useWindowSize();
  const showCarousel = useMemo(
    () => secondPreviewUrl && width <= mobileBreak,
    [width, secondPreviewUrl]
  );
  if (showCarousel) {
    return (
      <AliceCarousel
        autoPlay={ false }
        disableSlideInfo
        infinite
        mouseTracking
        mouseDragEnabled
        disableButtonsControls
      >
        <ImagePreview { ...props } />
        <div className={ Classes.secondPreview }>
          <img src={ secondPreviewUrl } alt="preview" />
        </div>
      </AliceCarousel>
    );
  }
  return <ImagePreview { ...props } otherImageUrl={ otherImageUrl } />;
};

ImagePreviewSwitcher.propTypes = {
  secondPreviewUrl: PropTypes.string,
  otherImageUrl: PropTypes.string
};

export default ImagePreviewSwitcher;
