import React, {
  useState, useRef, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MODAL_SELECTOR } from 'arts/config/constants';
import {
  Formik, Form, Field
} from 'formik';
import * as Classes from './styles.module.scss';
import Zoom from './zoom';
import Modal from '../Modal';

const CYCLE_CLASSES = [Classes.c1, Classes.c2, Classes.c3];

const hints = [
  'impressionist landscapes',
  'monochromatic abstract art',
  'fashion illustrations for girls room',
  'inspired by Monet',
  'pink art for kitchen',
  'vintage portraits',
  'antique style art',
  'California cool',
  'modern cityscapes',
  'impressionist landscapes'
];

const ExploreHeader = ({
  squery, onSubmit, onReset, onBack, subTitle
}) => {
  const initialValues = { squery };
  const aref = useRef(null);
  const [position, setPosition] = useState(0);
  const [focused, setFocused]  = useState(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      /* eslint-disable no-shadow */
      setPosition(position => (
        position === hints.length - 1 ? 0 : position + 1
      ));
    }, 1750);
    return () => clearInterval(interval);
  }, []);

  const classIndex = Math.abs(position % CYCLE_CLASSES.length);

  useEffect(() => {
    if (aref.current) {
      if (position) {
        aref.current.classList.add(Classes.animate);
      } else {
        aref.current.classList.remove(Classes.animate);
      }
      aref.current.style.top = `${ -position * 100 }%`;
    }
  }, [position]);

  const browse = useMemo(() => (
    subTitle ? (
      <p className={ Classes.browse }>
        { subTitle }
      </p>
    ) : (
      <p className={ Classes.browse }>
        Browse art that is “More Like This” and discover new artwork along the way.
      </p>
    )
  ), [subTitle]);

  const back = (onBack ? (
    <button type="button" onClick={ onBack } className={ Classes.icon }>

      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_158_200)">
          <circle cx="14" cy="14" r="13.5" stroke="black" />
          <path d="M16 8L10 14.1714L16 20" stroke="black" strokeLinecap="round" />
        </g>
        <defs>
          <clipPath id="clip0_158_200">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span>Back</span>
    </button>
  ) : null);

  const buttons = (resetForm) => (
    <div>
      <button
        type="button"
        className={ Classes.icon }
        onClick={ () => { resetForm({ values: { squery: '' } }); onReset(); } }
      >

        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_158_196)">
            <circle cx="14" cy="14" r="13.5" stroke="black" />
            <path d="M13.8926 8.05497C11.2141 7.53557 8 10.7931 8 13.7678C8 17.1982 10.878 20 14.4283 20C16.6642 20 20 18.3269 20 13.7678" stroke="black" strokeLinecap="round" />
            <path d="M17 8L12.5 10.5981L12.5 5.40192L17 8Z" fill="black" />
          </g>
          <defs>
            <clipPath id="clip0_158_196">
              <rect width="28" height="28" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>Start Over</span>
      </button>
      { back }
    </div>
  );

  return (
    <>
      {
        disclaimerVisible ? (
          <Modal
            selector={ MODAL_SELECTOR }
            onCancel={ () => setDisclaimerVisible(false) }
          >
            <h2>Disclaimer</h2>
            <p>
              Our Visual Search uses artificial intelligence technology and machine learning
              algorithms and tools (collectively, &quot;AI Technology&quot;), which in parts
              incorporate, or are based on, technology and training data developed and provided
              by third parties. The search results are generated by a machine and as such may
              not consistently match human expectations or represent the subject or theme intended
              by the user. Moreover, AI Technology may also produce results that could be inaccurate
              or considered inappropriate, offensive, or insensitive. In addition, the search
              output generated by AI Technology depends on various factors that are not within
              Artfully Walls&apos; control. Consequently, Artfully Walls is not responsible for,
              and disclaims all liability with respect to, the output generated by Visual Search.
              Users are encouraged to provide feedback if they encounter results that do not
              meet expectations at&nbsp;
              <a href="mailto:support@artfullywalls.com">support@artfullywalls.com</a>
              . This feedback may help refine and enhance the AI Technology&apos;s performance
              over time.
            </p>
          </Modal>
        ) : null
      }
      <div className={ Classes.root }>
        <div className={ Classes.divider } />
        <Formik initialValues={ initialValues } onSubmit={ onSubmit }>
          {
            ({ isSubmitting, resetForm, values }) => (
              <Form className={ Classes.form }>
                <div className={ Classes.buttons }>
                  { buttons(resetForm) }
                </div>
                <div className={ Classes.holder }>
                  <p>In your own words, describe what you&apos;re looking for:</p>
                  <div className={ Classes.search }>
                    <div className={ Classes.iHolder }>
                      <Field
                        type="text"
                        name="squery"
                        placeholder=""
                        disabled={ isSubmitting }
                        autoComplete="off"
                        onFocus={ () => setFocused(true) }
                        onBlur={ () => setFocused(false) }
                      />
                      <ul
                        className={
                          classnames(
                            Classes.hint,
                            Classes.animate,
                            CYCLE_CLASSES[classIndex],
                            { [Classes.hideHint]: (!!values.squery || focused) }
                          )
                        }
                        ref={ aref }
                      >
                        { hints.map((hint, index) => <li key={ index }>{ hint }</li>) }
                      </ul>
                    </div>
                    <button type="submit" className={ Classes.icon } aria-label="Zoom">
                      <Zoom />
                    </button>
                  </div>
                  <div className={ Classes.mobileButtons }>
                    { buttons(resetForm) }
                  </div>
                  { browse }
                  <div
                    className={ Classes.disclaimer }
                    onClick={ () => setDisclaimerVisible(true) }
                  >
                    <button type="button">Read Disclaimer About Our Visual Search</button>
                  </div>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </>
  );
};

ExploreHeader.propTypes = {
  squery: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default ExploreHeader;
