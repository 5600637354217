import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Classes from './styles.module.scss';

const StoreItem = ({
  wallId,
  workId,
  storeItemId,
  add,
  remove,
  className,
  paperId,
  frameId
}) => {
  const onAction = (e) => {
    e.preventDefault();
    if (storeItemId) {
      const id = wallId || workId;
      remove(id, storeItemId);
      return;
    }
    const props = { frameId, paperId };
    if (wallId) {
      props.wallId = wallId;
    } else {
      props.workId = workId;
    }
    add(props);
  };

  const action = storeItemId ? 'Remove from Store' : 'Add To Store';
  return (
    <button
      className={ classnames(Classes.storeItem, className) }
      onClick={ onAction }
      type="button"
    >
      { action }
    </button>
  );
};

StoreItem.propTypes = {
  wallId: PropTypes.number,
  workId: PropTypes.number,
  storeItemId: PropTypes.number,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  className: PropTypes.string,
  paperId: PropTypes.number,
  frameId: PropTypes.number
};

export default StoreItem;
