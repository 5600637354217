import React from 'react';
import T from 'prop-types';
import * as Classes from './styles.module.scss';

const Radio = (props) => (
  <div className={ Classes.radio }>
    <input
      type="radio"
      { ...props }
    />
    <span />
  </div>
);
Radio.propTypes = {
  checked: T.bool,
  id: T.oneOfType([T.string, T.number])
};
export default Radio;
