/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';
import MobileFilterLink from './mobile_filter_link';
import { BY_COLOR, renderColorSelection  } from './color_filters';

const MobileFilter = ({
  filterKey, title, options, onSelected
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(!expanded);

  const onClick = (eventKey) => onSelected(filterKey, eventKey);

  let section = null;

  if (expanded) {
    const cleanOptions = options.filter(v => v.isHidden !== true);
    section = (filterKey === BY_COLOR) ? (
      <section>
        <div className={ Classes.awColor }>
          { renderColorSelection(onClick, options, Classes.colorLine, Classes.singleColor) }
        </div>
      </section>
    ) : (
      <section>
        {
          cleanOptions.map(({ id, name }) => (
            <MobileFilterLink
              onClick={ onClick }
              name={ name }
              id={ id.toString() }
              key={ id }
            />
          ))
        }
      </section>
    );
  }

  return (
    <div>
      <h5
        className={ classnames({ [Classes.expanded]: expanded }) }
        onClick={ toggleExpand }
      >
        { title }
      </h5>
      { section }
    </div>
  );
};

MobileFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired
};

export default MobileFilter;
