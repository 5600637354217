import React, { useState, useEffect, useRef } from 'react';
import { associatePaypalOrderApi } from 'arts/config/api';
import { Loader } from 'semantic-ui-react';
import * as Classes from '../checkout_summary/styles.module.scss';

const PaypalButtonNoAddress = () => {
  const ref = useRef(null);
  const [savingPaypalOrder, setSavingPaypalOrder] = useState(false);

  useEffect(() => {
    if (!window.paypalCreateOrder || !window.paypal) { return; }
    window.paypal.Buttons({
      createOrder: (data, actions) => actions.order.create(window.paypalCreateOrder),
      onApprove: (data) => {
        setSavingPaypalOrder(true);
        associatePaypalOrderApi(
          window.paypalCreateOrder.purchase_units[0].reference_id,
          data.orderID
        ).then((/* { orderAssociated } */) => {
          if (!ref.current) { return; }
          window.location.reload();
        }).catch(() => {
          setSavingPaypalOrder(false);
        });
      },
      onShippingChange: ({
        shipping_address: shippingAddress
      }, actions) => {
        if (window.paypalAllowedCountries.indexOf(shippingAddress.country_code) < 0) {
          actions.reject();
        } else {
          actions.resolve();
        }
      }
    }).render('#paypal-button-container');
  }, [window.paypalCreateOrder]);

  const loader = savingPaypalOrder ? (
    <div className={ Classes.savingOrder }>
      <Loader active inline size="tiny" />
      <span>
        &nbsp;Saving PayPal order, please wait...
      </span>
    </div>
  ) : null;
  return (
    <div ref={ ref }>
      { loader }
      <div id="paypal-button-container" className={ Classes.paypal } style={ { display: (savingPaypalOrder ? 'none' : '') } } />
    </div>
  );
};

export default PaypalButtonNoAddress;
