// extracted by mini-css-extract-plugin
var _1 = "asJdWqq0nckEaCuoqs01";
var _2 = "JV4TKZsYXEophEtqeZ7b";
var _3 = "Rc0sVsIxe4gA7rCx8BJk";
var _4 = "l4J_ELXZJT6zciZ9Ilv3";
var _5 = "NAvlvktilNC7DlGTGKDl";
var _6 = "sIJZmzM1M5n_r59xB2Tm";
var _7 = "pwQpBE0APkO4yLrgsmYb";
var _8 = "vvUl7wpa79gz6apYzpQr";
var _9 = "x54ZOdnl0q9oZxtM8_09";
export { _1 as "canvas", _2 as "customImage", _3 as "draggingSilhouette", _4 as "meter", _5 as "silhouette", _6 as "welcome", _7 as "whiteBg", _8 as "work", _9 as "workingArea" }
