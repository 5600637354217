import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';

export const CategoryPrints = () => (
  <h2>
    A hand-picked collection of fine art prints
    <br />
    Unique framing options
    <br />
    Pre-designed gallery walls
  </h2>
);

CategoryPrints.propTypes = {
  title: PropTypes.string
};

const CategoryHeader = ({
  title,
  desktopImageHref,
  mobileImageHref,
  color
}) => {
  const styleD = { backgroundImage: `url(${ desktopImageHref })` };
  const styleM = { backgroundImage: `url(${ mobileImageHref })` };
  const bgStyle = {
    backgroundColor: `#${ color || 'fff' }`
  };

  const dImage = desktopImageHref ? (
    <div className={ Classes.imgD } style={ styleD } />
  ) : null;

  const mImage = mobileImageHref ? (
    <div className={ Classes.imgM } style={ styleM } />
  ) : (
    <div className={ Classes.imgMHolder } />
  );

  return (
    <div className={ AppClasses.innerContainer20 }>
      <div className={ Classes.header } style={ bgStyle }>
        { mImage }
        <div className={ classnames(AppClasses.headerText, Classes.headerText) }>
          <h1>{ title }</h1>
          <CategoryPrints />
        </div>
        { dImage }
      </div>
    </div>
  );
};

CategoryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  desktopImageHref: PropTypes.string.isRequired,
  mobileImageHref: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default CategoryHeader;
