/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import {
  Formik, Form, Field
} from 'formik';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../checkout/styles.module.scss';

const PlacePartnerOrder = ({
  partnerOrderUid,
  shippingSlipUrl,
  eventNumber
}) => {
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    document.querySelector('#place_partner_order input[name=partner_order_uid]').value = values.partnerOrderUid;
    document.querySelector('#place_partner_order input[name=shipping_slip_url]').value = values.shippingSlipUrl;
    document.querySelector('#place_partner_order input[name=event_number]').value = values.event_number;
    document.getElementById('place_partner_order').submit();
  };

  const initialValues = {
    partnerOrderUid,
    shippingSlipUrl,
    eventNumber
  };

  return (
    <div className={ Classes.content }>
      <Formik
        initialValues={ initialValues }
        onSubmit={ onSubmit }
      >
        {
          ({
            isSubmitting
          }) => (
            <Form className={ Classes.genericForm }>
              <div className={ Classes.row } role="group">
                <div className={ Classes.wideField }>
                  <label htmlFor="partnerOrderUid">
                    Partner Order Id
                  </label>
                  <Field id="partnerOrderUid" type="text" name="partnerOrderUid" disabled={ isSubmitting } />
                </div>
              </div>
              <div className={ Classes.row } role="group">
                <div className={ Classes.wideField }>
                  <label htmlFor="shippingSlipUrl">
                    Shipping Slip URL
                  </label>
                  <Field id="shippingSlipUrl" type="text" name="shippingSlipUrl" disabled={ isSubmitting } />
                </div>
              </div>
              <div className={ Classes.row } role="group">
                <div className={ Classes.wideField }>
                  <label htmlFor="eventNumber">
                    Event Number
                  </label>
                  <Field id="eventNumber" type="text" name="eventNumber" disabled={ isSubmitting } />
                </div>
              </div>
              <div className={ Classes.submitRow } role="group">
                {
                  isSubmitting ? (
                    <Loader active inline />
                  ) : (
                    <button type="submit" className={ classnames(AppClasses.button, Classes.button) }>
                      PLACE PARTNER
                    </button>
                  )
                }
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  );
};

PlacePartnerOrder.propTypes = {
  partnerOrderUid: PropTypes.string,
  shippingSlipUrl: PropTypes.string,
  eventNumber: PropTypes.string
};

export default PlacePartnerOrder;
