import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import { getRewardedPromoCode } from 'arts/config/api';
import Left from 'arts/assets/chevron_left';
import Right from 'arts/assets/chevron_right';
import { FooterText } from './index';
import * as Classes from './styles.module.scss';

const PromoRewards = () => {
  const [page, setPage] = useState(1);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [promos, setPromos] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    setLoading(true);
    getRewardedPromoCode(page).then(({ promoCodes, perPage, page: newPage }) => {
      if (!ref.current || newPage !== page) { return; }
      setPromos(promoCodes);
      setLoading(false);
      setHasNextPage(promoCodes.length === perPage);
      setHasPrevPage(page > 1);
    }).catch(() => {
      setLoading(false);
    });
  }, [page]);

  const onPrevPage = () => {
    if (hasPrevPage) {
      setPage(page - 1);
    }
  };

  const onNextPage = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  };

  let content = null;
  if (isLoading) {
    content = (
      <Loader active inline />
    );
  } else {
    content = promos.length ? (
      <>
        <button disabled={ !hasPrevPage } type="button" onClick={ onPrevPage } aria-label="Left">
          <Left />
        </button>
        <button disabled={ !hasNextPage } type="button" onClick={ onNextPage } aria-label="Right">
          <Right />
        </button>
        <table>
          <thead>
            <tr>
              <th>Promo Code</th>
              <th>Discount</th>
              <th>Rewarded At</th>
              <th>By</th>
            </tr>
          </thead>
          <tbody>
            {
              promos.map(({
                code, discountPercent, by, rewardedAt
              }) => (
                <tr key={ code }>
                  <td>{ code }</td>
                  <td>
                    { discountPercent }
                    %
                  </td>
                  <td>{ rewardedAt }</td>
                  <td>{ by }</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </>
    ) : (
      <div>
        No Promo Rewards Yet
      </div>
    );
  }

  return (
    <div className={ Classes.referrals } ref={ ref }>
      <div className={ classnames(Classes.image, Classes.image3) } />
      <div className={ Classes.referral }>
        <div className={ Classes.r }>
          <div className={ Classes.header }>
            <h1>
              Promo Rewards
            </h1>
            <div className={ Classes.div } />
            <p />
          </div>
          { content }
          <p className={ Classes.footer1 }>
            <FooterText />
          </p>
        </div>
      </div>
    </div>
  );
};

export default PromoRewards;
