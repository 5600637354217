/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import numeral from 'arts/config/numeral';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import Modal from 'arts/components/Modal';
import Constants, { MODAL_SELECTOR } from 'arts/config/constants';
import { shippingDetailsItems } from 'arts/components/extra_info/shipping_details';
import { associatePaypalOrderApi } from 'arts/config/api';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';

export const FORMAT = '$0,0.00';
const TBD = 'TBD';

export const renderValue = (label, value, comment = null) => (
  <li>
    <span className={ Classes.label }>
      { label }
    </span>
    <span className={ Classes.value }>
      { value }
      {
        comment ? (
          <>
            <br />
            { comment }
          </>
        ) : null
      }
    </span>
  </li>
);

export const numberOrTbd = (value) => (
  value ? numeral(value).format(FORMAT) : TBD
);

const CheckoutSummary = ({
  merchandise,
  designerDiscountPercent,
  designerDiscount,
  totalMerchandise,
  shipping,
  shippingComment,
  tax,
  giftsTotal,
  giftsUsed,
  totalLabel,
  totalValue,
  calculatingShipping,
  affirmCents,
  url,
  reloadUrl,
  noRedirect,
  loading,
  checkoutButton,
  allAvailable,
  history,
  locale,
  paypalCreateOrderString
}) => {
  const [shippingDetails, showShippingDetails] = useState(false);
  const [savingPaypalOrder, setSavingPaypalOrder] = useState(false);

  const paypalCheckoutPath = `${ window.currentLocale.basename }/my/order?invalidate_order=true`;
  const noPaypalcheckoutPath = `${ window.currentLocale.basename }/my/order?invalidate_order=true&forget=true`;

  const showDetails = (e) => { e.preventDefault(); showShippingDetails(true); };
  const hideDetails = () => { showShippingDetails(false); };

  if (locale) { numeral.locale(locale); }

  const ref = useRef(null);

  useEffect(() => {
    if (affirmCents && window.affirm && window.affirm.ui && window.affirm.ui.ready) {
      window.affirm.ui.ready(() => {
        window.affirm.ui.refresh();
      });
    }
  }, [affirmCents]);

  useEffect(() => {
    if (!checkoutButton || !allAvailable || !window.paypal) { return; }
    window.paypal.Buttons({
      createOrder: (data, actions) => actions.order.create(window.paypalCreateOrder),
      onApprove: (data) => {
        setSavingPaypalOrder(true);
        associatePaypalOrderApi(
          window.paypalCreateOrder.purchase_units[0].reference_id,
          data.orderID
        ).then(({ orderAssociated }) => {
          if (!ref.current) { return; }
          if (orderAssociated) {
            window.location.href = paypalCheckoutPath;
          } else {
            window.location.reload();
          }
        }).catch(() => {
          setSavingPaypalOrder(false);
        });
      },
      onShippingChange: ({
        shipping_address: shippingAddress
      }, actions) => {
        if (window.paypalAllowedCountries.indexOf(shippingAddress.country_code) < 0) {
          actions.reject();
        } else {
          actions.resolve();
        }
      }
    }).render('#paypal-button-container');
  }, [checkoutButton, allAvailable]);

  const skipLoader = noRedirect || window.location.pathname === url;

  if (url && !noRedirect) {
    if (reloadUrl) {
      window.location.href = url;
    } else {
      if (paypalCreateOrderString) {
        window.paypalCreateOrder = JSON.parse(paypalCreateOrderString);
      }
      setTimeout(() => {
        history.push(url);
      }, 0);
    }
  }

  if (loading) {
    return (
      <Loader active inline>Loading summary...</Loader>
    );
  }

  const renderShippingDetails = shippingDetails ? (
    <Modal
      selector={ MODAL_SELECTOR }
      onCancel={ hideDetails }
      headerText={ <strong>ABOUT SHIPPING</strong> }
    >
      <>
        { shippingDetailsItems() }
      </>
    </Modal>
  ) : null;

  let submit = null;
  if (checkoutButton && allAvailable) {
    if (savingPaypalOrder) {
      submit = (
        <div className={ Classes.savingOrder }>
          <Loader active inline size="tiny" />
          <span>
            Saving PayPal order, please wait...
          </span>
        </div>
      );
    } else {
      submit = (
        <>
          <div className={ Classes.surround }>
            <a
              href={ noPaypalcheckoutPath }
              className={ classnames(Classes.button, AppClasses.button) }
            >
              Checkout
            </a>
          </div>
          <div id="paypal-button-container" className={ Classes.paypal } />
          <div className={ Classes.tos }>
            By continuing to checkout, you are agreeing to
            our <a target="_blank" href={ `${ window.currentLocale.basename }/terms_of_use` }>Terms Of Use</a> and <a target="_blank" href={ `${ window.currentLocale.basename }/privacy` }>Privacy Policy</a>.
          </div>
        </>
      );
    }
  }

  const total = totalLabel ? (
    <li className={ Classes.total }>
      <span className={ Classes.label }>
        { totalLabel }
      </span>
      {
        totalValue ? (
          <span className={ Classes.value }>
            {
              url && !skipLoader ? (
                <>
                  <Loader active inline size="tiny" />
                  &nbsp;
                </>
              ) : null
            }
            { numeral(totalValue).format(FORMAT) }
          </span>
        ) : null
      }
      {
        calculatingShipping ? (
          <span className={ Classes.calculating }>
            <Loader active inline size="tiny" />
            &nbsp;
            { calculatingShipping }
          </span>
        ) : null
      }
    </li>
  ) : null;

  const giftsTotals = giftsTotal ? (
    <>
      { renderValue('Total', numeral(giftsTotal).format(FORMAT)) }
      { renderValue('Gift Card', numeral(giftsUsed).format(FORMAT)) }
    </>
  ) : null;

  const affirm = affirmCents ? (
    <li>
      <div className={ Classes.affirmPromo }>
        <p className="affirm-as-low-as" data-page-type="payment" data-amount={ affirmCents } />
      </div>
    </li>
  ) : null;

  const renderTax = (locale === Constants.locales.uk) ? null : renderValue('Tax', numberOrTbd(tax));

  return (
    <div className={ Classes.summary } ref={ ref }>
      <ul>
        { renderValue('Merchandise', numeral(merchandise).format(FORMAT)) }
        {
          designerDiscountPercent
            ? renderValue(`Designer Discount ${ designerDiscountPercent }%`, numeral(designerDiscount).format(FORMAT))
            : null
        }
        {
          designerDiscountPercent
            ? renderValue('Total Merchandise', numeral(totalMerchandise).format(FORMAT))
            : null
        }
        { renderValue('Shipping', numberOrTbd(shipping), shippingComment) }
        { renderTax }
        { giftsTotals }
        { total }
      </ul>
      <div className={ Classes.checkout }>
        { submit }
        <div className={ Classes.final }>
          <ul>
            { affirm }
            <li>
              * All prints and frames are final sale and non-returnable.
            </li>
            <li>
              * All prints and frames are custom made to order and take 10-14 business days to ship.
            </li>
            <li>
              <button type="button" onClick={ showDetails }>
                Learn more about our shipping
              </button>
            </li>
          </ul>
          { renderShippingDetails }
        </div>
      </div>
    </div>
  );
};
CheckoutSummary.propTypes = {
  merchandise: PropTypes.string,
  designerDiscountPercent: PropTypes.number,
  designerDiscount: PropTypes.string,
  totalMerchandise: PropTypes.string,
  shipping: PropTypes.string,
  shippingComment: PropTypes.string,
  tax: PropTypes.string,
  totalLabel: PropTypes.string,
  totalValue: PropTypes.string,
  calculatingShipping: PropTypes.string,
  url: PropTypes.string,
  reloadUrl: PropTypes.bool,
  noRedirect: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  pendingCartItemsCount: PropTypes.number,
  affirmCents: PropTypes.number,
  checkoutButton: PropTypes.bool,
  giftsTotal: PropTypes.string,
  giftsUsed: PropTypes.string,
  allAvailable: PropTypes.bool.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  locale: PropTypes.string.isRequired,
  paypalCreateOrderString: PropTypes.string
};

export default CheckoutSummary;
