import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';

const brokenDescription = (text) => (text || '').replace(/<br>/gi, '\n');

const renderLink = (href, useRealLink, attrs, children) => {
  if (useRealLink) {
    return (
      <a href={ href } { ...attrs }>
        { children }
      </a>
    );
  }
  return (
    <Link to={ href } { ...attrs }>
      { children }
    </Link>
  );
};

const Collection = ({
  name,
  slug,
  topMenuImage,
  description,
  actionLabel,
  collectionHref,
  useRealLink
}) => {
  const href = collectionHref || `/collections/${ slug }`;
  return (
    <li>
      {
        topMenuImage ? (
          renderLink(href, useRealLink, { className: Classes.imgL }, (
            <img src={ topMenuImage } alt={ name } />
          ))) : null
      }
      <h3>
        { renderLink(href, useRealLink, {}, name) }
      </h3>
      <div style={ { '-webkit-box-orient': 'vertical' } }>
        <ReactMarkdown>
          { brokenDescription(description) }
        </ReactMarkdown>
      </div>
      {
        renderLink(href, useRealLink, { className: AppClasses.actionButton }, actionLabel)
      }
    </li>
  );
};

Collection.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string,
  id: PropTypes.number,
  collectionHref: PropTypes.string,
  topMenuImage: PropTypes.string,
  description: PropTypes.string,
  actionLabel: PropTypes.string.isRequired,
  useRealLink: PropTypes.bool
};

export default Collection;
