import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../walls/styles.module.scss';

const Container = React.forwardRef(({ children }, ref) => (
  <ul className={ classnames(AppClasses.gridContainer2inRow, Classes.grid) } ref={ ref }>
    { children }
  </ul>
));

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default Container;
