/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../checkout/styles.module.scss';

const AddGiftCard = ({
  code, number, error
}) => {
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    document.querySelector('#add_gift_card_usage input[name=card_number]').value = values.number;
    document.querySelector('#add_gift_card_usage input[name=pin_code]').value = values.code;
    document.getElementById('add_gift_card_usage').submit();
  };

  const initialValues = { code, number };

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ onSubmit }
    >
      {
        ({
          isSubmitting
        }) => (
          <Form className={ Classes.genericForm }>
            <div className={ Classes.row } role="group">
              <div className={ Classes.field }>
                <label htmlFor="number">
                  Card Number
                </label>
                <Field id="firstName" type="text" name="number" disabled={ isSubmitting } />
                <ErrorMessage name="number" component="div" className={ Classes.error } />
              </div>
              <div className={ Classes.field }>
                &nbsp;
              </div>
            </div>
            <div className={ Classes.row } role="group">
              <div className={ Classes.field }>
                <label htmlFor="code">
                  Pin Code
                </label>
                <Field id="firstName" type="text" name="code" disabled={ isSubmitting } />
                <ErrorMessage name="code" component="div" className={ Classes.error } />
                {
                  error ? (
                    <div className={ Classes.error }>
                      { error }
                    </div>
                  ) : null
                }
              </div>
              <div className={ Classes.field }>
                <label>&nbsp;</label>
                {
                  isSubmitting ? (
                    <Loader active inline />
                  ) : (
                    <button type="submit" className={ classnames(AppClasses.button, Classes.applyButton) }>
                      USE GIFT CARD
                    </button>
                  )
                }
              </div>
            </div>
          </Form>
        )
      }
    </Formik>
  );
};

AddGiftCard.propTypes = {
  code: PropTypes.string,
  number: PropTypes.string,
  error: PropTypes.string
};

export default AddGiftCard;
