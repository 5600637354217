import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { executeRecaptcha, subscribeToNewsletterApi } from 'arts/config/api';
import SubscribeForm from './subscribe_form';
import ThanksPopup from './thanks_popup';
import * as Classes from './styles.module.scss';
import { onUserNewletterSubscribed } from '../../config/analytics';

const SubscribeToNewsletterFooter = ({ disallow }) => {
  if (window.location.pathname === '/promo') {
    return null;
  }
  const ref = useRef(null);
  const [thanks, setThanks] = useState(null);

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    const { email } = values;
    setSubmitting(true);
    executeRecaptcha('subscribe').then((token) => {
      subscribeToNewsletterApi(email, token).then(({ emailHash, email: newEmail, eventId }) => {
        if (!ref.current) { return; }
        setSubmitting(false);
        if (newEmail) {
          onUserNewletterSubscribed(emailHash, eventId);
          setThanks(newEmail);
        } else {
          setErrors({ email: 'Invalid email address' });
        }
      }).catch(() => {
        if (!ref.current) { return; }
        setSubmitting(false);
      });
    });
  };

  if (thanks) {
    return <ThanksPopup onCancel={ disallow } />;
  }
  return (
    <div ref={ ref } className={ Classes.subscribeFooter }>
      <h5>
        Save 15% off your purchase
      </h5>
      <p>
        Sign up for our newsletter
      </p>
      <SubscribeForm onSubmit={ onSubmit } />
    </div>
  );
};

SubscribeToNewsletterFooter.propTypes = {
  disallow: PropTypes.func.isRequired
};

export default SubscribeToNewsletterFooter;
