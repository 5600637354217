import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'arts/config/numeral';
import * as Classes from './styles.module.scss';
import CartItem from '../cart_items/cart_item';
import { FORMAT } from '../checkout_summary/checkout_summary';
import { multipleItems } from '../cart_items/cart_items';

const CartItemsPopup = ({
  collection, savedItem, pendingCartItemsCount, merchandise, resetSavedItem, locale
}) => {
  if (locale) { numeral.locale(locale); }
  useEffect(() => {
    const el = document.querySelector('a.pending-cart-items span');
    if (el && pendingCartItemsCount) {
      el.innerHTML = `${ pendingCartItemsCount }`;
    }
  }, [collection]);

  useEffect(() => {
    if (savedItem) {
      setTimeout(() => resetSavedItem(), 5000);
    }
  }, [savedItem]);

  if (!savedItem) {
    return null;
  }

  const content = savedItem.id ? (
    <div className={ Classes.items }>
      <CartItem { ...savedItem } editable={ false } realLinks locale={ locale } />
    </div>
  ) : (
    <div className={ Classes.items }>
      <h5>
        { savedItem.count }
        &nbsp;items added
      </h5>
    </div>
  );

  return (
    <div className={ Classes.added }>
      <h3>
        ADDED TO YOUR CART
        <button type="button" onClick={ resetSavedItem }>
          &times;
        </button>
      </h3>
      { content }
      <div className={ Classes.subtotal }>
        <span>
          { multipleItems(pendingCartItemsCount, ' in cart') }
        </span>
        <span>
          Subtotal:&nbsp;
          { numeral(merchandise).format(FORMAT) }
        </span>
      </div>
      <div className={ Classes.cart }>
        <a href={ `${ window.currentLocale.basename }/cart_items` } className={ Classes.view }>
          View your cart
        </a>
        <a href={ `${ window.currentLocale.basename }/cart_items` } className={ Classes.checkout }>
          CHECKOUT
        </a>
      </div>
    </div>
  );
};

CartItemsPopup.propTypes = {
  collection: PropTypes.array,
  pendingCartItemsCount: PropTypes.number,
  merchandise: PropTypes.string,
  savedItem: PropTypes.shape({
    id: PropTypes.number,
    count: PropTypes.number
  }),
  resetSavedItem: PropTypes.func.isRequired,
  locale: PropTypes.string
};

export default CartItemsPopup;
