import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import * as ItemClasses from '../WorkListItem/styles.module.scss';
import Info from '../WorkListItem/info';
import GTMPageView from '../Application/data_layer';
import { FORMAT } from '../checkout_summary/checkout_summary';
import { onCollectionImpression, onCollectionItemClick } from '../../config/analytics';
import Subtitle from './subtitle';
import QuickShop from '../QuickShopModal/quick_shop';

const SIZES = {
  wall: 2,
  work: 1,
  description: 3
};

const rawPriceByItem = ({ price }) => (price ? Number(price) : null);

const priceByItem = ({ details, price, locale }) => {
  const priceBase = rawPriceByItem({ details, price });
  if (priceBase && locale) {
    numeral.locale(locale);
  }
  return priceBase ? numeral(priceBase).format(FORMAT) : null;
};

export const renderItem = (collectionId, item, pos, useExactPrice = false) => {
  const {
    kind, id, imageUrl, title, by, href, sharingCaption, path, storableId, workPicture, locale,
    authorSlug, authorId
  } = item;
  const key = `${ kind }-${ id }`;
  const klass = Classes[`${ kind }C${ pos % 3 }`];
  let priceRange;
  if (useExactPrice) {
    const { price: basePrice } = workPicture;
    numeral.locale(locale);
    priceRange = numeral(basePrice).format(FORMAT);
  } else {
    const priceBase = priceByItem(item);
    priceRange = kind === 'work' ? `From: ${ priceBase }` : priceBase;
  }
  const infoProps = {
    title,
    authorName: by,
    href: path,
    priceRange,
    id: storableId,
    authorSlug,
    authorId,
    likesProps: {
      id: storableId,
      href,
      imageUrl,
      description: sharingCaption,
      favType: kind === 'work' ? 'Work' : 'Wall'
    },
    skipQuickShop: (kind !== 'work')
  };

  if (kind === 'original_art') {
    infoProps.title = `${ title } (original art)`;
    infoProps.likesProps = null;
    infoProps.skipQuickShop = true;
  }

  const onClick = () => {
    if (kind === 'work' && collectionId && pos) {
      onCollectionItemClick(item, rawPriceByItem, collectionId, pos);
    }
  };

  return [SIZES[kind], (
    <li key={ key } className={ classnames(ItemClasses.root, Classes.item, klass) }>
      <div className={ ItemClasses.anchor }>
        <Link to={ path } className={ ItemClasses.keeper } title={ title } onClick={ onClick }>
          <img
            alt={ title }
            src={ imageUrl }
          />
        </Link>
      </div>
      <Info { ...infoProps } />
    </li>
  )];
};

const mappedItems = (collectionId, items, description2) => {
  const res = [];
  let pos = 0;

  const d2 = description2 ? (
    <li className={ classnames(Classes.item, Classes.itemText) } key="desc">
      <Subtitle text={ description2 } />
    </li>
  ) : null;

  items.forEach(item => {
    if (pos === 9 && d2) {
      res.push(d2);
    }
    const [inc, i] = renderItem(collectionId, item, pos);
    pos += inc;
    res.push(i);
  });
  return res;
};

const Collection = ({
  name, description, description2, bannerImageHref, mobileBannerImageHref, items, id
}) => {
  useEffect(() => {
    onCollectionImpression({ id, items, priceByItem: rawPriceByItem });
  }, [id]);

  const img = mobileBannerImageHref ? (
    <>
      <img src={ bannerImageHref } alt={ name } className={ Classes.imgD } />
      <img src={ mobileBannerImageHref } alt={ name } className={ Classes.imgM } />
    </>
  ) : (
    <img src={ bannerImageHref } alt={ name } className={ Classes.img } />
  );

  return (
    <div className={ Classes.coll2 }>
      <h1>{ name }</h1>
      { name ? <GTMPageView pageType="category" section="collection" key={ name } /> : null }
      { img }
      <Subtitle text={ description } />
      <QuickShop />
      <ul className={ AppClasses.gridContainer3inRow }>
        { mappedItems(id, items, description2) }
      </ul>
    </div>
  );
};

Collection.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description2: PropTypes.string,
  bannerImageHref: PropTypes.string.isRequired,
  mobileBannerImageHref: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default Collection;
