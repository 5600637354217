/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useRef, useEffect, useState
} from 'react';
import { loadOrderStatus, executeRecaptcha } from 'arts/config/api';
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../checkout/styles.module.scss';
import { toCamelCase } from '../../config/convertCase';
import OrderDetails from './order_details';

const validate = (values) => {
  const errors = {};
  ['orderId', 'zip'].forEach(f => {
    if (!values[f]) {
      errors[f] = 'Required field';
    }
  });
  return errors;
};

const QueryForm = () => {
  const ref = useRef(null);
  const inputRef = useRef(null);
  const [theOrder, setOrder] = useState(null);

  useEffect(() => {
    if (inputRef.current) { inputRef.current.focus(); }
  }, []);

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    const { orderId, zip } = values;

    executeRecaptcha('query_order').then(token => {
      loadOrderStatus(orderId, zip, token).then(({ order, errors }) => {
        if (!ref.current) { return; }
        if (errors) {
          setErrors(errors);
          setSubmitting(false);
          return;
        }
        if (order) {
          setSubmitting(false);
          setOrder(toCamelCase(order));
        }
      }).catch(() => {
        setErrors({ orderId: 'Cannot query order status, please try again later.' });
        setSubmitting(false);
      });
    });

    setSubmitting(true);
  };

  if (theOrder) {
    return (
      <OrderDetails { ...theOrder } />
    );
  }

  return (
    <>
      <h1>Order Status</h1>
      <Formik
        initialValues={ { orderId: '', zip: '' } }
        validate={ validate }
        onSubmit={ onSubmit }
      >
        {
          ({
            isSubmitting
          }) => (
            <Form ref={ ref } className={ classnames(Classes.genericForm, Classes.guest) }>
              <div className={ Classes.guestRow }>
                <div className={ Classes.field }>
                  <label htmlFor="orderId">
                    Order Number
                  </label>
                  <Field
                    type="number"
                    name="orderId"
                    disabled={ isSubmitting }
                    innerRef={ inputRef }
                  />
                  <ErrorMessage name="orderId" component="div" className={ Classes.error } />
                </div>
              </div>

              <div className={ Classes.guestRow }>
                <div className={ Classes.field }>
                  <label htmlFor="zip">
                    Postal Code
                  </label>
                  <Field
                    type="text"
                    name="zip"
                    disabled={ isSubmitting }
                  />
                  <ErrorMessage name="zip" component="div" className={ Classes.error } />
                </div>
              </div>

              <div className={ Classes.guestRow }>
                {
                  isSubmitting ? (
                    <Loader active inline />
                  ) : (
                    <button type="submit" className={ classnames(AppClasses.button, Classes.button) }>
                      CHECK STATUS
                    </button>
                  )
                }
              </div>
            </Form>
          )
        }
      </Formik>
    </>
  );
};

export default QueryForm;
