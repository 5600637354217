import React from 'react';
import T from 'prop-types';

import Transition from './transition';
import * as Classes from './styles.module.scss';

const Collapsible = ({ children, collapsed }) => (
  <section className={ Classes.root }>
    <Transition collapsed={ collapsed }>
      { children }
    </Transition>
  </section>
);

Collapsible.propTypes = {
  children: T.node,
  collapsed: T.bool
};

export default Collapsible;
