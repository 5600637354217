import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import Collection from './collection';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import GTMPageView from '../Application/data_layer';

const MyFavorites = ({
  remove,
  load,
  loading,
  loaded,
  Work,
  Wall,
  User
}) => {
  useEffect(() => {
    if (!loaded && !loading) {
      load();
    }
  }, []);

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="other" section="my" />
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        <h1>My Favorites</h1>
        <div>
          {
            loading && <Loader active inline>Loading favorites...</Loader>
          }
          <Collection
            name="Walls"
            items={ Wall }
            remove={ remove }
            className={ AppClasses.gridContainer3inRow }
          />
          <Collection
            name="Art"
            items={ Work }
            remove={ remove }
            className={ AppClasses.gridContainer3inRow }
          />
          <Collection
            name="Artists"
            items={ User }
            remove={ remove }
            className={ AppClasses.gridContainer4inRow }
          />
        </div>
      </div>
    </div>
  );
};

MyFavorites.propTypes = {
  remove: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,

  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,

  Wall: PropTypes.object.isRequired,
  Work: PropTypes.object.isRequired,
  User: PropTypes.object.isRequired
};

export default MyFavorites;
