import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { request, toCamelCase } from 'arts/../shared/request';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import { onWorkItems, trackEvent } from '../../config/analytics';
import SimilarGrid from './similar_grid';
import * as AppClasses from '../Application/styles.module.scss';
import Scroll from '../Application/scroll';
import * as Classes from './styles.module.scss';
import ExploreHeader from '../explore/explore_header';

const defaultWorkAndItems = (intId) => {
  if (window.moreLikeThis) {
    if (window.moreLikeThis.work.id === intId) {
      return toCamelCase(window.moreLikeThis);
    }
  }
  return {
    work: null, items: []
  };
};

const visualSearchPath = (query) => `${ window.currentLocale.basename }/visual-search?squery=${ encodeURIComponent(query) }`;

const VisualSearch = () => {
  const { id } = useParams();
  const history = useHistory();
  const intId = parseInt(id, 10);

  const { explore_context: exploreContext, explore } = queryString.parse(window.location.search);
  const { work: w, items: i, clip: u } = defaultWorkAndItems(intId);

  const [loading, setLoading] = useState(!w);
  const [work, setWork] = useState(w);
  const [items, setItems] = useState(i);
  const [useClip, setUseClip] = useState(u);

  useEffect(() => {
    // find work
    if (work && work.id === intId) {
      return;
    }
    const foundWork = items.find(fw => fw.id === intId);
    if (foundWork) {
      setWork(foundWork);
      setItems([]);
    }

    let path = `/more-like-this/${ id }`;
    if (exploreContext) {
      path += `?explore_context=${ exploreContext }`;
    }

    request(path).then(response => {
      const { work: w1, items: i1, clip: clip1 } = toCamelCase(response);
      setUseClip(clip1);
      setWork(w1);
      setItems(i1.map(item => ({ ...item, explore })));
      setLoading(false);
    }).catch(() => {});
  }, [id]);

  useEffect(() => {
    if (items && items.length && id) {
      onWorkItems({ items, listName: 'similar-popup', pageType: 'product' });
    }
  }, [items, id]);

  useEffect(() => {
    if (work) {
      trackEvent('visual search', { title: work.title, 'work id': work.id, useClip });
    }
  }, [(work || {}).id]);

  const onSubmit = (values, { setSubmitting }) => {
    const { squery: newQuery } = values;
    window.location.href = visualSearchPath(newQuery);
    setSubmitting(false);
  };

  const onReset = () => {
    window.location.href = visualSearchPath('');
  };

  const pageH1 = useMemo(() => {
    if (explore || !work) { return null; }
    const { title } = work;
    return `Visual Search: Art similar to ${ title }`;
  }, [(work || {}).title, explore]);

  const subTitle = useMemo(() => {
    if (!work) { return null; }
    const { authorName, title } = work;
    return (
      <>
        Browse art prints that are similar to&nbsp;
        <Link to={ work.path }>
          { title }
          &nbsp;by&nbsp;
          { authorName }
        </Link>
      </>
    );
  }, [(work || {}).authorName, (work || {}).title]);

  const header = explore ? (
    <ExploreHeader
      onSubmit={ onSubmit }
      onReset={ onReset }
      onBack={ () => history.goBack() }
      subTitle={ subTitle }
    />
  ) : (
    <>
      <h1>{ pageH1 }</h1>
      <h2>{ subTitle }</h2>
    </>
  );

  return (
    <div className={ classnames(AppClasses.innerContainerArt, Classes.vSearch) }>
      <Scroll key={ id } />
      { header }
      {
        loading
          ? <Loader active inline className={ Classes.loader } />
          : <SimilarGrid work={ work } items={ items } />
      }
    </div>
  );
};

export default VisualSearch;
