import React, { PureComponent } from 'react';
import T from 'prop-types';

import * as Classes from './styles.module.scss';

class Transition extends PureComponent {
  static propTypes = {
    children: T.node,
    collapsed: T.bool
  };

  constructor(props) {
    super(props);

    const { collapsed } = props;

    this.state = {
      initiallyCollapsed: collapsed
    };

    this.setRootRef = this.setRootRef.bind(this);
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { collapsed } = this.props;

    if (collapsed && !prevProps.collapsed) {
      this.hide();
    } else if (!collapsed && prevProps.collapsed) {
      this.open();
    }
  }

  componentWillUnmount() {
    this.cancelAnimations();
  }

  onTransitionEnd() {
    this.rootRef.removeEventListener('transitionend', this.onTransitionEnd);
    this.rootRef.style.maxHeight = 'none';
    this.rootRef.style.overflow = 'visible';
  }

  setRootRef(ref) {
    this.rootRef = ref;
  }

  hide() {
    this.cancelAnimations();
    this.rootRef.style.maxHeight = `${ this.rootRef.scrollHeight }px`;
    this.rootRef.style.overflow = 'hidden';

    this.raf = window.requestAnimationFrame(() => {
      this.raf = window.requestAnimationFrame(() => {
        this.rootRef.style.maxHeight = '0';
      });
    });
  }

  open() {
    this.cancelAnimations();
    this.rootRef.addEventListener('transitionend', this.onTransitionEnd);
    this.rootRef.style.maxHeight = `${ this.rootRef.scrollHeight }px`;
  }

  cancelAnimations() {
    this.rootRef.removeEventListener('transitionend', this.onTransitionEnd);
    window.cancelAnimationFrame(this.raf);
  }

  render() {
    const { children } = this.props;
    const { initiallyCollapsed } = this.state;
    const className = `
      ${ initiallyCollapsed ? Classes.collapsed : '' }
      ${ Classes.transitionRoot }
    `;

    return (
      <div
        ref={ this.setRootRef }
        className={ className }
      >
        { children }
      </div>
    );
  }
}

export default Transition;
