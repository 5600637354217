import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../checkout/styles.module.scss';
import Unsubscribe from './unsubscribe';
import PaypalButtonNoAddress from './paypal_button_no_address';

const PaypalPlaceOrderButton = ({
  placeOrder, orderId, method, hasPPOrder
}) => {
  const [unsubscribe, setUnsubscribe] = useState(false);
  const handleUnsubsribeChange = (event) => {
    const { target } = event;
    setUnsubscribe(target.checked);
  };

  if (!method) {
    return null;
  }

  const place = () => {
    if (method) {
      placeOrder(orderId, unsubscribe);
    }
  };

  const placeButton = hasPPOrder ? (
    <button
      className={ classnames(AppClasses.button, Classes.button) }
      onClick={ place }
      type="button"
      disabled={ !method }
    >
      Place Order
    </button>
  ) : (
    <PaypalButtonNoAddress />
  );

  return (
    <div className={ Classes.placeOrderH }>
      { placeButton }
      <Unsubscribe
        checked={ unsubscribe }
        onChange={ handleUnsubsribeChange }
      />
    </div>
  );
};

PaypalPlaceOrderButton.propTypes = {
  placeOrder: PropTypes.func.isRequired,
  hasPPOrder: PropTypes.bool,
  orderId: PropTypes.number.isRequired,
  method: PropTypes.string
};

export default PaypalPlaceOrderButton;
