import React, { PureComponent } from 'react';
import classnames from 'classnames';
import T from 'prop-types';
import {
  paperShape
} from 'arts/reducers/work';
import { humanSize } from 'arts/config/lib';
import Radio from 'arts/components/Radio';
import * as Classes from './styles.module.scss';

class Header extends PureComponent {
  static propTypes = {
    paper: paperShape.isRequired,
    active: T.bool,
    frameless: T.bool,
    selectFrame: T.func.isRequired,
    selectNoFrame: T.func.isRequired,
    currentPrice: T.number,
    currency: T.string,
    landscape: T.bool,
    onClick: T.func.isRequired,
    skipSelectFrame: T.bool
  }

  constructor() {
    super();

    this.selectFrame = this.selectFrame.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { onClick } = this.props;
    e.stopPropagation();
    onClick();
  }

  selectFrame(e) {
    e.stopPropagation();
    const { frameless, selectFrame, selectNoFrame } = this.props;
    if (frameless)  { selectFrame(); } else { selectNoFrame(); }
  }

  hasFrames() {
    const { paper } = this.props;
    return !!paper.options.find((f) => f.frameId);
  }

  renderAction() {
    const { frameless, skipSelectFrame } = this.props;
    if (skipSelectFrame) { return null; }
    const action = frameless
      ? '+ Add frame'
      : '- Remove frame';

    return (
      <button
        type="button"
        className={ Classes.frameAddRemove }
        onClick={ this.selectFrame }
      >
        { action }
      </button>
    );
  }

  renderActions() {
    const { paper, active } = this.props;
    if (paper.limitations) {
      return (
        <div>
          { paper.limitations }
        </div>
      );
    }
    return active && this.hasFrames() ? this.renderAction() : null;
  }

  render() {
    const {
      paper: {
        displayPaperWidth,
        displayPaperHeight,
        displayPrintWidth,
        displayPrintHeight,
        options,
        paperId,
        isHidden
      },
      active,
      currentPrice,
      currency,
      landscape
    } = this.props;

    const hiddenIcon = isHidden
      ? [<span key="span">&nbsp;</span>, <i key="i" className="icon-eye-close" />]
      : null;

    const price = active
      ? currentPrice.toFixed(2) || Math.abs(options[0].price).toFixed(2)
      : Math.abs(options[0].price).toFixed(2);

    return (
      <div className={ Classes.header } onClick={ this.onClick }>
        <Radio
          readOnly
          id={ paperId }
          checked={ active }
        />
        <div className={ Classes.label }>
          <span>
            { humanSize(landscape, displayPaperWidth, displayPaperHeight, 'X') }
          </span>

          <span className={ classnames(Classes.printSize, { [Classes.printSizeUk]: window.currentLocale.currentLocale === 'uk' }) }>
            { `(image ${ humanSize(landscape, displayPrintWidth, displayPrintHeight, 'x') }${ window.currentLocale.unit })` }
          </span>
        </div>
        <div className={ Classes.price }>
          <span>
            { `${ currency }${ price }` }
            { hiddenIcon }
          </span>
          <span className={ Classes.printSize }>&nbsp;</span>
        </div>
        { this.renderActions() }
      </div>
    );
  }
}

export default Header;
