import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { trackEvent } from '../../config/analytics';
import Eye from '../Viewer/eye';
import * as Classes from './styles.module.scss';
// import SimilarModal from '../similar_modal';

const SimilarItems = ({
  className, eventSource, children, id
}) => {
  const onClick = () => trackEvent('more like this', { source: eventSource, 'work id': id });

  return (
    <div className={ classnames(className, Classes.more) }>
      <Link
        to={ `/more-like-this/${ id }` }
        onClick={ onClick }
      >
        { children }
        <Eye />
        <span>
          More Like This
        </span>
      </Link>
    </div>
  );
};

SimilarItems.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  eventSource: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default SimilarItems;
