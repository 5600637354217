import React from 'react';
import PropTypes from 'prop-types';

const Image = ({
  widthIn, heightIn, dpi, src, className
}) => {
  const style = {
    width: widthIn * dpi,
    height: heightIn * dpi
  };

  return (
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    <img
      alt="image"
      src={ src }
      style={ style }
      className={ className }
    />
  );
};

Image.propTypes = {
  widthIn: PropTypes.number.isRequired,
  heightIn: PropTypes.number.isRequired,
  dpi: PropTypes.number.isRequired,
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  className: PropTypes.string
};

export default Image;
