import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Dropdown } from 'semantic-ui-react';
import ReactRouterPropTypes from 'react-router-prop-types';

import queryString from 'query-string';

import MobileFilter from './mobile_filter';
import { BY_COLOR, renderColorSelection  } from './color_filters';
import WorkFilter from '../work_filter';

import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';

export const NEW_ARRIVALS = 'newest';
const BY_COUNTRY = 'by_country';

const renderMenuItem = (id, name, key, onSelect) => {
  const onClick = () => onSelect(id);
  return (
    <Dropdown.Item key={ id } value={ id } text={ name } onClick={ onClick } />
  );
};

const MAX_ELEMENTS = 8;
const ITEMS_PER_COLUMN = 5;
const MAX_ELEMENTS_COUNTRY = 180;

const renderMenu = (options, key, offset, onSelect, maxElements) => {
  if (key === BY_COLOR) {
    return options ? (
      <div className={ Classes.awColor }>
        { renderColorSelection(onSelect, options, Classes.colorLine, Classes.singleColor) }
      </div>
    ) : null;
  }
  if (options.length <= maxElements) {
    return (
      <div className={ Classes.menuWrapper } key={ `o_${ offset }` }>
        { options.map((o) => renderMenuItem(o.id, o.name, key, onSelect)) }
      </div>
    );
  }
  let itemsDone = 0;
  const columns = [];
  while (itemsDone < options.length) {
    const column = options.slice(itemsDone, itemsDone + ITEMS_PER_COLUMN);
    if (column.length > 0) {
      columns.push(renderMenu(column, key, itemsDone, onSelect, 999));
    }
    itemsDone += column.length;
  }

  return columns;
};

const renderFilter = (filter, onSelected) => {
  const onSelect = (eventKey) => onSelected(filter.key, eventKey);
  const options = filter.options.filter(v => v.isHidden !== true);
  const maxElements = filter.key === BY_COUNTRY ? MAX_ELEMENTS_COUNTRY : MAX_ELEMENTS;
  return (
    <Dropdown
      key={ filter.title }
      className={
        classnames(Classes.filterDropDown, {
          [Classes.filterByCountry]: filter.key === BY_COUNTRY
        })
      }
      text={ filter.title }
      button
      value={ null }
    >
      <Dropdown.Menu>
        { renderMenu(options, filter.key, 0, onSelect, maxElements) }
      </Dropdown.Menu>
    </Dropdown>
  );
};

const renderSelection = (filter, value, key, onRemoved, skipFilterKeys) => {
  if (!value || (skipFilterKeys || []).includes(key)) { return null; }
  let optionName = null;
  if (filter.key === BY_COLOR) {
    let hexColor;
    if (filter.mapping[value]) {
      hexColor = parseInt(filter.mapping[value], 10).toString(16).padStart(6, '0');
    } else {
      hexColor = parseInt(value, 10).toString(16).padStart(6, '0');
    }
    optionName = (
      <div className={ Classes.byColor }>
        Color:&nbsp;
        <div style={ { backgroundColor: `#${ hexColor }` } } />
      </div>
    );
  } else {
    const option = filter.options.find((v) => v.id.toString() === value.toString());
    if (!option) { return null; }
    optionName = option.name;
  }
  const onRemove = (e) => {
    e.preventDefault();
    onRemoved(key);
  };
  return (
    <button key={ filter.key } className={ Classes.remove } onClick={ onRemove } type="button">
      { optionName }
      {' '}
      &times;
    </button>
  );
};

const renderWithWork = (workId, onRemoved) => {
  if (!workId) { return null; }
  const onRemove = (e) => {
    e.preventDefault();
    onRemoved('with_work_id');
  };
  return (
    <WorkFilter
      workId={ workId }
      className={ Classes.remove }
      onClick={ onRemove }
    />
  );
};

const renderQuery = (query, onRemoved) => {
  if (!query) { return null; }
  const onRemove = (e) => {
    e.preventDefault();
    onRemoved('query');
  };
  return (
    <button className={ Classes.remove } onClick={ onRemove } type="button">
      { query }
      {' '}
      &times;
    </button>
  );
};

const renderNewArrival = (hasNewest, onRemoved, skipFilterKeys) => {
  if (!hasNewest) { return null; }
  if ((skipFilterKeys || []).includes(NEW_ARRIVALS)) { return null; }
  const onRemove = (e) => {
    e.preventDefault();
    onRemoved(NEW_ARRIVALS);
  };
  return (
    <button className={ Classes.remove } onClick={ onRemove } type="button">
      New Arrivals &times;
    </button>
  );
};

const paramsFromProps = (props, skipKeys) => {
  const params = {};
  props.filters.forEach((key) => {
    if (props[key] && !(skipKeys || []).includes(key)) {
      params[key] = props[key];
    }
  });
  return params;
};

export const buildPath = (path, params) => {
  const sign = path.indexOf('?') === -1 ? '?' : '&';
  return `${ path }${ sign }${ queryString.stringify(params) }`;
};

const buildCustomPath = (path, filterName, numberId) => {
  const revertedMap = {};
  Object.keys(window.slugsMaps[filterName]).forEach((k) => {
    revertedMap[window.slugsMaps[filterName][k]] = k;
  });
  const entries = path.split(/\//);
  if (entries.length === 5) {
    // /art-prints/style/foo/var
    if (filterName === 'categories') {
      entries[entries.length - 1] = revertedMap[numberId] || numberId;
    } else {
      // style
      entries[entries.length - 2] = revertedMap[numberId] || numberId;
    }
  } else {
    // /art-prints/style/foo
    // /art-prints/category/bar
    entries[entries.length - 1] = revertedMap[numberId] || numberId;
  }
  return entries.join('/');
};

export default class Filter extends PureComponent {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    filters: PropTypes.arrayOf(PropTypes.string).isRequired,
    [PropTypes.string]: PropTypes.string,
    newest: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    query: PropTypes.string,
    artFilters: PropTypes.arrayOf(PropTypes.any),
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    children: PropTypes.node,
    with_work_id: PropTypes.number,
    skipFilterKeys: PropTypes.array,
    defaultPath: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  onSelected = (selectedKey, id) => {
    const {
      path, onChange, skipFilterKeys, defaultPath
    } = this.props;
    const params = paramsFromProps(this.props, skipFilterKeys);
    if (!(skipFilterKeys || []).includes(selectedKey)) {
      params[selectedKey] = id;
      this.props.history.push(buildPath(path, params));
    } else {
      const filterName = window.slugsMaps.filtersMap[selectedKey];
      let newPath = null;
      // has a custom path like /categoires/landscape
      const vIndex = Object.values(window.slugsMaps[filterName]).indexOf(id);
      const kIndex = Object.keys(window.slugsMaps[filterName]).indexOf(id);
      if (vIndex < 0 && kIndex < 0) {
        // no = default path
        params[selectedKey] = id;
        newPath = defaultPath;
      } else {
        // yes - build the path
        newPath = buildCustomPath(path, filterName, id);
      }
      this.props.history.push(buildPath(newPath, params));
    }
    if (onChange) {
      onChange();
    }
    this.setState({ open: false });
  };

  onRemove = (key) => {
    const { path, onChange, skipFilterKeys } = this.props;
    const params = paramsFromProps(this.props, skipFilterKeys);
    delete params[key];
    this.props.history.push(buildPath(path, params));
    if (onChange) {
      onChange();
    }
  }

  onClearAll = (e) => {
    const { path, onChange } = this.props;
    e.preventDefault();
    this.props.history.push(buildPath(path, {}));
    if (onChange) {
      onChange();
    }
  }

  renderRemoveAll() {
    return (
      <button className={ Classes.clearAll } onClick={ this.onClearAll } type="button">
        Clear All
      </button>
    );
  }

  onLabelClick = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const {
      label, artFilters, children, filters, query, newest, with_work_id: withWorkId, skipFilterKeys
    } = this.props;
    if (!artFilters) { return null; }

    const allFilters = artFilters.filter(
      (f) => filters.includes(f.key)
    );

    const params = Object.keys(paramsFromProps(this.props, skipFilterKeys));
    const hasFilters = params.length > 0 && !(params.length === 1 && params[0] === 'page');
    const removeAll = hasFilters ? this.renderRemoveAll() : null;

    const labelClass = classnames(Classes.searchBy, {
      [Classes.expanded]: open
    });

    const mobileFilter = open ? (
      <div className={ Classes.mobileFilter }>
        <div className={ Classes.filterLists }>
          {
            allFilters.map((f) => (
              <MobileFilter
                filterKey={ f.key }
                onSelected={ this.onSelected }
                key={ f.key }
                { ...f }
              />
            ))
          }
        </div>
      </div>
    ) : null;

    return (
      <div
        className={ Classes.filterHolder }
      >
        <div className={ AppClasses.innerContainer20 }>
          <div className={ Classes.filter }>
            <div className={ Classes.list }>
              <div className={ labelClass } onClick={ this.onLabelClick }>
                { label }
              </div>
              { mobileFilter }
              {
                allFilters.map((f) => renderFilter(f, this.onSelected))
              }
            </div>
          </div>
          <div className={ Classes.values }>
            {
              allFilters.map(
                (f) => renderSelection(f, this.props[f.key], f.key, this.onRemove, skipFilterKeys)
              )
            }
            { renderWithWork(parseInt(withWorkId, 10), this.onRemove) }
            { renderQuery(query, this.onRemove) }
            { renderNewArrival(newest, this.onRemove, skipFilterKeys) }
            { removeAll }
          </div>
        </div>
        { children }
      </div>
    );
  }
}
