import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';
import ZoomModal from './zoom_modal';
import Zoomin from './zoomin_icon.js';

const ZoomIn = ({
  zoomPreviewUrl,
  withFrame,
  className
}) => {
  const [visible, setVisible] = useState(null);
  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  const modal = useMemo(() => (
    visible ? (
      <ZoomModal
        img={ zoomPreviewUrl }
        onHide={ hide }
        withFrame={ withFrame }
      />
    ) : null
  ), [visible]);

  return useMemo(() => {
    if (!zoomPreviewUrl) {
      return null;
    }

    return (
      <div className={ className }>
        { modal }
        <button className={ Classes.zoomIcon } onClick={ show } type="button" title="Zoom In" aria-label="Zoom In">
          <Zoomin />
        </button>
      </div>
    );
  }, [zoomPreviewUrl, visible]);
};

ZoomIn.propTypes = {
  zoomPreviewUrl: PropTypes.string,
  withFrame: PropTypes.bool,
  className: PropTypes.string
};

export default ZoomIn;
