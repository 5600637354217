import React from 'react';
import PropTypes from 'prop-types';
import Expandable from 'arts/components/expandable';
import Constants from 'arts/config/constants';
import { shippingDetailsItems } from 'arts/components/extra_info/shipping_details';
import { mat } from 'arts/components/PapersMenu/mounts';
import { CANVAS, TAPESTRY } from '../../config/constants';

const ExtraInfo = ({ aboutPrints, selection }) => {
  // if (!selection || !selection.paper) return null;

  const { currentLocale } = window.currentLocale;
  const isUK = currentLocale === 'uk';

  let frameDetails = null;

  switch (selection && selection.paper && selection.paper.paperMaterial) {
    case TAPESTRY:
      frameDetails = (
        <Expandable title="Hanging Rods" eKey={ Constants.Expandable.KEYS.frameDetails }>
          <p>
            Our tapestries are anchored with a hanging rod on the top and one on the bottom.
            All of our hanging rods are made from wood.
          </p>
        </Expandable>
      );
      break;
    case CANVAS:
      frameDetails = (
        <Expandable title="Frames" eKey={ Constants.Expandable.KEYS.frameDetails }>
          {
            isUK ? (
              <p>
                Canvas prints do not require a hanging wire and can be hung
                directly on a screw or nail.
              </p>
            ) : (
              <p>
                Our canvas prints are professionally framed with a woodgrain frame,
                designed specifically for framing canvas. The frame has a stepped inner
                edge to add subtle depth, and comes ready to hang with a hanging wire.
              </p>
            )
          }
        </Expandable>
      );
      break;
    default: {
      const plexiGlass = isUK ? 'plexi-glass' : 'an archival UV plexi-glass';
      frameDetails = (
        <Expandable title="Frames" eKey={ Constants.Expandable.KEYS.frameDetails }>
          <p>
            Our fine art paper prints and photographs are professionally framed
            with&nbsp;
            { plexiGlass }
            , and come ready to hang on your wall
            with a hanging wire. Prints can be framed with a&nbsp;
            { mat() }
            &nbsp;white mat,
            or without.
          </p>
        </Expandable>
      );
      break;
    }
  }

  return (
    <div>
      <Expandable title="Prints" eKey={ Constants.Expandable.KEYS.prints }>
        {
          (aboutPrints || []).map((v, i) => (
            <p key={ i }>{ v }</p>
          ))
        }
      </Expandable>
      { frameDetails }
      <Expandable title="Shipping" eKey={ Constants.Expandable.KEYS.shipping }>
        { shippingDetailsItems() }
      </Expandable>
    </div>
  );
};

ExtraInfo.propTypes = {
  selection: PropTypes.object,
  aboutPrints: PropTypes.arrayOf(PropTypes.string)
};

export default ExtraInfo;
