import React from 'react';
import T from 'prop-types';
import * as AppClasses from '../Application/styles.module.scss';

const Container = ({ children }) => (
  <div className={ AppClasses.innerContainer20 }>
    <ul className={ AppClasses.gridContainer3inRow }>
      { children }
    </ul>
  </div>
);
Container.propTypes = {
  children: T.node.isRequired
};

export default Container;
