import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as AppClasses from 'arts/components/Application/styles.module.scss';
import * as Classes from 'arts/components/walls/styles.module.scss';
import { usePrevious } from 'arts/config/hooks';
import Filter from '../works/filter';
import BreadCrumbsGeneric from '../bread_crumbs';
import GridSelection, { THUMB } from './grid_selection';
import { VALID_QUERY_PARAMS, BREADCRUMBS_LINKS } from './shared';
import GTMPageView from '../Application/data_layer';

const mapSlugsToIds = (params) => {
  const out = { ...params };
  Object.keys(out).forEach(key => {
    if (key === 'with_home_decor_style_id' || key === 'with_room_id') {
      if (!parseInt(out[key], 10)) {
        const slugMap = (key === 'with_home_decor_style_id') ? window.slugsMaps.home_decor_styles : window.slugsMaps.rooms;
        const id = slugMap[out[key]];
        out[key] = id;
      }
    }
  });
  return out;
};

const GALERRY_WALLS = 'Gallery Walls';

const getParams = (params, pathname, wallFilters) => {
  if (pathname === '/walls') {
    return [mapSlugsToIds(params), [], GALERRY_WALLS];
  }
  // string to id using window.slugsMaps - udpate params and skipFilterKeys
  // URL to params using pathname - udpate params and skipFilterKeys
  if (pathname.match(/\/walls\/room/)) {
    const slug = pathname.split('/').pop();
    const id = parseInt(window.slugsMaps.rooms[slug], 10);
    const filter = (wallFilters || []).find(f => f.key === 'with_room_id');
    const title = filter ? filter.options.find(o => o.id === id).name : '';
    return [mapSlugsToIds({ ...params, with_room_id: id }), [], `${ title } ${ GALERRY_WALLS }`];
  }

  if (pathname.match(/\/walls\/style/)) {
    const slug = pathname.split('/').pop();
    const id = parseInt(window.slugsMaps.home_decor_styles[slug], 10);
    const filter = (wallFilters || []).find(f => f.key === 'with_home_decor_style_id');
    const title = filter ? filter.options.find(o => o.id === id).name : '';
    return [mapSlugsToIds({ ...params, with_home_decor_style_id: id }), [], `${ title } ${ GALERRY_WALLS }`];
  }

  return [params, []];
};

const WallsList = ({
  fetchWallFilters,
  resetWalls,
  // filters
  wallFilters,
  activeFilters,
  history,
  // children
  loadNextPage,
  children
}) => {
  const prevFilters = usePrevious(activeFilters);

  const [
    queryParams, skipFilterKeys, title
  ] = useMemo(
    () => getParams(activeFilters, window.location.pathname, wallFilters),
    [window.location.pathname, window.location.search, wallFilters]
  );

  const resetWallsFilter = () => {
    resetWalls();
    setTimeout(loadNextPage, 0);
  };

  useEffect(() => {
    if (activeFilters.with_work_id !== (prevFilters || {}).with_work_id) {
      resetWallsFilter();
    }
  }, [activeFilters.with_work_id]);

  useEffect(() => {
    if (!wallFilters) {
      fetchWallFilters();
    }
  }, []);

  return (
    <div className={ AppClasses.container20 }>
      <div className={ Classes.title }>
        <h1>{ title }</h1>
      </div>
      <GTMPageView pageType="category" section="gallery walls" />
      <Filter
        path={ `/walls?as=${ THUMB }` }
        label="Search walls by"
        history={ history }
        { ...queryParams }
        filters={ VALID_QUERY_PARAMS }
        artFilters={ wallFilters }
        onChange={ resetWallsFilter }
        skipFilterKeys={ skipFilterKeys }
      >
        <div className={ Classes.bcrumbs }>
          <BreadCrumbsGeneric className={ Classes.wallBc } links={ BREADCRUMBS_LINKS }>
            <GridSelection path="/walls" grid={ THUMB } params={ queryParams } />
          </BreadCrumbsGeneric>
        </div>
      </Filter>
      { children }
    </div>
  );
};

WallsList.propTypes = {
  resetWalls: PropTypes.func.isRequired,
  fetchWallFilters: PropTypes.func.isRequired,
  // filters
  wallFilters: PropTypes.arrayOf(PropTypes.any),
  activeFilters: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  // children
  loadNextPage: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default WallsList;
