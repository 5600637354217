import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { worksShape } from 'arts/reducers/works';
import WorkListItem, { WorkListItemSkeleton } from 'arts/components/WorkListItem';
import ArtistTitle, { ArtistTitleSkeleton } from './artist_title';
import ArtistDetails from './artist_details';
import Scroll from '../Application/scroll';
import ArtistOriginalArts from '../artist_original_arts';
import GTMPageView from '../Application/data_layer';
import { trackEvent, onWorkClick } from '../../config/analytics';
import QuickShop from '../QuickShopModal/quick_shop';

import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';

const ArtistWorks = ({
  id,
  slug,
  works,
  artistInfo,
  fetchArtistInfo,
  hasOriginalArt
}) => {
  if (!id) { return null; }

  const {
    name,
    avatarUrl,
    background,
    exhibitions,
    statement,
    city,
    country,
    styles
  } = artistInfo;

  useEffect(() => { fetchArtistInfo(id); }, [id]);

  useEffect(() => {
    if (name && id) {
      trackEvent('artist page', {
        'author id': id,
        'author name': name
      });
    }
  }, [id, name]);

  const renderArtistInfo = name ? (
    <ArtistTitle
      name={ name }
      avatarUrl={ avatarUrl }
      authorId={ id }
      authorSlug={ slug }
      city={ city }
      country={ country }
    />
  ) : (
    <ArtistTitleSkeleton />
  );

  const artistDetails = name ? (
    <ArtistDetails
      background={ background }
      exhibitions={ exhibitions }
      statement={ statement }
      styles={ styles }
    />
  ) : null;

  const printTitle = hasOriginalArt ? (
    <div className={ AppClasses.innerContainer20 }>
      <div className={ Classes.section }>
        <div className={ Classes.title }>
          Art Prints
        </div>
      </div>
    </div>
  ) : null;

  const onItemClick = (position) => onWorkClick(works[position], 'category', 'artist-works', position);

  return (
    <div className={ AppClasses.container20 }>
      <Scroll />
      {id ? <GTMPageView pageType="category" section="artists" key={ id } /> : null }
      <div className={ AppClasses.innerContainer20 }>
        { renderArtistInfo }
      </div>
      { printTitle }
      <div className={ AppClasses.innerContainer20 }>
        <QuickShop />
        <ul className={ AppClasses.gridContainer3inRow }>
          {
            works.map((w, index) => (
              <WorkListItem
                data={ w }
                key={ w.id }
                loading={ works.length === 0 }
                position={ index }
                onClick={ onItemClick }
              />
            ))
          }
          {
            works.length === 0 ? (
              <>
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
                <WorkListItemSkeleton />
              </>
            ) : null
          }
        </ul>
      </div>
      <div className={ AppClasses.innerContainer20 }>
        <ArtistOriginalArts id={ id } />
        { artistDetails }
      </div>
    </div>
  );
};

ArtistWorks.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string,
  works: worksShape.isRequired,
  artistInfo: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    background: PropTypes.string,
    exhibitions: PropTypes.array,
    statement: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    styles: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }))
  }).isRequired,
  fetchArtistInfo: PropTypes.func.isRequired,
  hasOriginalArt: PropTypes.bool.isRequired
};

export default ArtistWorks;
