import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';

const filterArtsists = (artists, letter) => artists.filter(a => a.letter === letter);
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const Artists = ({ artists }) => {
  const [currentLetter, setCurrentLetter] = useState('A');
  const [currentArtists, setCurrentArtists] = useState(filterArtsists(artists, currentLetter));

  useEffect(() => { setCurrentArtists(filterArtsists(artists, currentLetter)); }, [currentLetter]);

  const renderArtists = useMemo(() => {
    if (currentArtists.length) {
      const gridTemplateRows = Math.max(16, Math.ceil(currentArtists.length / 4));
      return (
        <div className={ Classes.names } style={ { gridTemplateRows: `repeat(${ gridTemplateRows }, 1fr)` } }>
          {
            currentArtists.map(artist => (
              <a href={ artist.href } key={ artist.href } title={ artist.name }>
                { artist.name }
              </a>
            ))
          }
        </div>
      );
    }
    return (
      <p>
        No artists starting with the letter&nbsp;
        <strong>
          { currentLetter }
        </strong>
        &nbsp;were found.
      </p>
    );
  }, [currentArtists.length, currentLetter]);

  return (
    <>
      <div className={ Classes.letter }>
        <div className={ Classes.current }>
          { currentLetter }
        </div>
        <div className={ Classes.letters }>
          {
            letters.map(letter => (letter === currentLetter ? null : (
              <button type="button" key={ letter } onClick={ () => setCurrentLetter(letter) }>
                { letter }
              </button>
            )))
          }
        </div>
      </div>
      <div className={ Classes.namesKeeper }>
        { renderArtists }
      </div>
    </>
  );
};

Artists.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    letter: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  })).isRequired
};

export default Artists;
