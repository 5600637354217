import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import numeral from 'arts/config/numeral';
import Item from './item';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../WorkListItem/styles.module.scss';
import { FORMAT } from '../checkout_summary/checkout_summary';

const Wall = ({
  id,
  name,
  imageUrl,
  path,
  totalPrice,
  remove,
  locale
}) => {
  const removeFavorite = () => remove(id, 'Wall');
  if (locale) { numeral.locale(locale); }

  return (
    <Item
      name={ name }
      path={ path }
      imageUrl={ imageUrl }
      className={ AppClasses.wall }
    >
      <div className={ Classes.left }>
        <Link to={ path } className={ Classes.art } title={ name }>
          &nbsp;
          <span>
            { name }
          </span>
        </Link>
        { numeral(totalPrice).format(FORMAT) }
      </div>
      <div className={ Classes.removeFavorite }>
        <button type="button" onClick={ removeFavorite } title="Remove">
          &times;
        </button>
      </div>
    </Item>
  );
};

Wall.propTypes = {
  id: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  totalPrice: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};

export default Wall;
