import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import classnames from 'classnames';
import * as AppClasses from '../Application/styles.module.scss';
import Section from './section';
import * as Classes from './styles.module.scss';
import CartItems from '../cart_items';
import CheckoutSummary from '../checkout_summary';
import ShowAddress from '../addresses/show';
import EditAddress from '../addresses/edit';
import GTMPageView from '../Application/data_layer';
import EmailSection from './email_section';

const EditAddresses = ({ location, history }) => {
  const params = queryString.parse(location.search);
  const isBilling = !!params.billing;

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="checkout" section="checkout" />
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        <h1>Checkout</h1>
      </div>
      <div className={ AppClasses.gridCheckout }>
        <div className={ AppClasses.left }>
          <EmailSection />
          <Section title="SHIPPING ADDRESS" edit="/my/address/edit">
            {
              isBilling
                ? <ShowAddress />
                : <EditAddress />
            }
          </Section>
          <Section title="BILLING ADDRESS" edit="/my/address/edit?billing=true">
            {
              isBilling
                ? <EditAddress billing />
                : <ShowAddress billing />
            }
          </Section>
          <Section title="PLACE ORDER" />
        </div>
        <div className={ AppClasses.right }>
          <Section title="SUMMARY OF CHARGES">
            <div className={ Classes.content }>
              <CheckoutSummary history={ history } />
            </div>
          </Section>
          <Section title="SHOPPING CART" edit="/cart_items">
            <div className={ Classes.content }>
              <CartItems narrow checkoutStep={ 3 } />
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};

EditAddresses.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired
};

export default EditAddresses;
