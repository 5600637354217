import React, { PureComponent } from 'react';
import T from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import classnames from 'classnames';
import {
  paperShape,
  frameShape
} from 'arts/reducers/work';
import Collapsible from 'arts/components/Collapsible';
import Header from './header';
import Frame from './frame';
// eslint-disable-next-line import/named
import Mounts, {
  FULL_BLEED, MATTE, FLOAT_DECKLED, FLOAT_STRIGHT
} from './mounts';
import SelectPlexiGlass from './select_plexi_glass';
import { aliceSliderSettins, handleDragStart } from '../slider_arrows';
import Warning from './warning';
import * as Classes from './styles.module.scss';

const groupBy = (xs, key) => xs.reduce((rv, x) => {
  // eslint-disable-next-line no-param-reassign
  rv[x[key]] = rv[x[key]] || [];
  rv[x[key]].push(x);
  return rv;
}, {});

const generateUniqFrames = (options) => {
  const res = [];
  const groups = groupBy(options, 'frameName');
  const order = [MATTE, FULL_BLEED, FLOAT_DECKLED, FLOAT_STRIGHT];
  Object.keys(groups).forEach((k) => {
    const g = groups[k];
    for (let i = 0, l = order.length; i < l; i += 1) {
      const mount = order[i];
      const frame = g.find(f => f.mount === mount);
      if (frame) {
        res.push(frame);
        return;
      }
    }
  });
  return res;
};

const hasMount = (options, mount, frameName) => !!options.find(
  i => i.mount === mount && i.frameName === frameName
);

class Paper extends PureComponent {
  static propTypes = {
    data: paperShape.isRequired,
    setFrame: T.func.isRequired,
    setNonGlare: T.func.isRequired,
    selectedPaperId: T.number,
    selectedFrame: frameShape,
    currentPrice: T.number,
    currency: T.string,
    landscape: T.bool,
    updateHeader: T.func.isRequired,
    thumbnails: T.array,
    nonGlare: T.bool
  }

  constructor(props) {
    super(props);
    const {
      options
    } = props.data;

    this.state = {
      availableFrames: generateUniqFrames(options)
    };

    this.selectFrame = this.selectFrame.bind(this);
    this.setFrame = this.setFrame.bind(this);
    this.selectNoFrame = this.selectNoFrame.bind(this);
    this.selectFirstAvailableFrame = this.selectFirstAvailableFrame.bind(this);
    this.selectMount = this.selectMount.bind(this);
    this.renderFrame = this.renderFrame.bind(this);
    this.renderCollapsibleHeader = this.renderCollapsibleHeader.bind(this);
  }

  componentDidMount() {
    this.updateHeader();
  }

  componentDidUpdate() {
    this.updateHeader();
  }

  updateHeader() {
    this.props.updateHeader(this.props.data.paperId, this.renderCollapsibleHeader());
  }

  selectFrame(frame) {
    this.props.setFrame(this.props.data, frame);
  }

  selectFirstAvailableFrame() {
    this.props.setFrame(this.props.data, this.state.availableFrames[0]);
    this.props.setNonGlare(false);
  }

  selectNoFrame() {
    this.props.setFrame(this.props.data, this.props.data.options[0]);
    this.props.setNonGlare(false);
  }

  setFrameAndMount(frameName, mount) {
    if (frameName && mount) {
      let frame = this.props.data.options
        .find(i => i.mount === mount && i.frameName === frameName);
      if (frame) {
        this.selectFrame(frame);
        return;
      }
      // any mount
      frame = this.props.data.options.find(i => i.frameName === frameName);
      if (frame) {
        this.selectFrame(frame);
        this.props.setNonGlare(false);
        return;
      }
      this.selectFirstAvailableFrame();
    }
  }

  setFrame(frame) {
    const { mount } = this.props.selectedFrame;
    const { frameName } = frame;
    this.setFrameAndMount(frameName, mount);
  }

  selectMount(mount) {
    const { frameName } = this.props.selectedFrame;
    this.setFrameAndMount(frameName, mount);
  }

  renderCollapsibleHeader() {
    const {
      selectedPaperId,
      selectedFrame,
      landscape,
      data,
      currency
    } = this.props;

    const { isTapestry } = selectedFrame || {};

    return (
      <Header
        skipSelectFrame={ isTapestry }
        selectFrame={ this.selectFirstAvailableFrame }
        selectNoFrame={ this.selectNoFrame }
        paper={ data }
        active={ selectedPaperId ? data.paperId === selectedPaperId : false }
        frameless={ selectedFrame === data.options[0] }
        currentPrice={ this.props.currentPrice }
        currency={ currency }
        landscape={ landscape }
        key={ data.paperId }
        onClick={ this.selectNoFrame }
      />
    );
  }

  renderFrame(frame, index) {
    const { frameName } = this.props.selectedFrame || {};
    return (
      <Frame
        key={ index }
        data={ frame }
        onSelect={ this.setFrame }
        selected={ frameName === frame.frameName }
        handleDragStart={ handleDragStart }
      />
    );
  }

  renderAvailableFrames() {
    const categories = this.state.availableFrames
      .map((f) => f.frameCategory)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();
    const res = categories.map((category) => {
      const frames = this.state.availableFrames.filter((f) => f.frameCategory === category);
      let frameDescription = null;
      let renderWarning = null;
      if (this.props.selectedFrame && this.props.selectedFrame.frameCategory === category) {
        const {
          frameName, frameDisplayWidth, frameDisplayDepth, warning, frameLabel, frameCategory
        } = this.props.selectedFrame;
        // this is tapestry
        const label = frameLabel === frameCategory ? '' : 'frame ';
        frameDescription = ` - ${ frameName }, ${ label }width ${ frameDisplayWidth }${ window.currentLocale.unit }, depth ${ frameDisplayDepth }`;
        renderWarning = <Warning warning={ warning } />;
      }
      return (
        <div key={ category }>
          <div className={ Classes.category }>
            { category }
            <span>{ frameDescription }</span>
          </div>
          { renderWarning }
          <div className={ Classes.frames }>
            { frames.map(this.renderFrame) }
          </div>
          <div className={ Classes.mobileFrames }>
            <AliceCarousel
              { ...aliceSliderSettins(false) }
            >
              { frames.map(this.renderFrame) }
            </AliceCarousel>
          </div>
        </div>
      );
    });
    return res;
  }

  render() {
    const {
      data, selectedFrame, thumbnails, nonGlare
    } = this.props;
    const {
      frameName, mount, nonGlarePrice, isTapestry
    } = selectedFrame || {};
    const uid = data.paperId;
    const collapsed = isTapestry ? false : (data.options.indexOf(this.props.selectedFrame) <= 0);

    const { url: previewUrl } = (thumbnails || []).find((t) => t.type === 'framed') || {};
    const withMatte = hasMount(data.options, MATTE, frameName) ? 1 : 0;
    const withFullBleed = hasMount(data.options, FULL_BLEED, frameName) ? 1 : 0;
    const withDckl = hasMount(data.options, FLOAT_DECKLED, frameName) ? 1 : 0;
    const withFltn = hasMount(data.options, FLOAT_STRIGHT, frameName) ? 1 : 0;

    const hasStep2 = (withMatte + withFullBleed + withDckl + withFltn) > 1;
    const step2 = hasStep2 ? (
      <Mounts
        selected={ mount }
        onSelect={ this.selectMount }
        paperId={ data.paperId }
        matte={ withMatte > 0 }
        fullBleed={ withFullBleed > 0 }
        dckl={ withDckl > 0 }
        fltn={ withFltn > 0 }
      />
    ) : null;

    const step3 = hasStep2 && nonGlarePrice ? (
      <div className={ Classes.step3 }>
        <div className={ Classes.sectionTitle }>
          <span>Step 3 - </span>
          Select UV Plexiglass
        </div>
        <SelectPlexiGlass nonGlare={ nonGlare } setNonGlare={ this.props.setNonGlare } />
      </div>
    ) : null;

    return (
      <Collapsible
        uid={ uid }
        key={ uid }
        collapsed={ collapsed }
      >
        <section className={ Classes.mouldingSection }>
          <div className={ classnames(Classes.sectionTitle, { [Classes.hidden]: !hasStep2 }) }>
            <span>Step 1 - </span>
            Select Moulding
          </div>
          { this.renderAvailableFrames() }
        </section>

        <section className={ Classes.options }>
          <div className={ Classes.previewImage }>
            {
              previewUrl ? (
                <img src={ previewUrl } alt="" />
              ) : null
            }
          </div>
          <div>
            <div className={ classnames(Classes.sectionTitle, { [Classes.hidden]: !hasStep2 }) }>
              <span>Step 2 - </span>
              Select Mount
            </div>
            { step2 }
            { step3 }
          </div>
        </section>
      </Collapsible>
    );
  }
}

export default Paper;
