import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';

const Zoom = ({
  style,
  className,
  zoomPreviewUrl,
  children
}) => {
  const el = useRef(null);
  const cz = useRef(null);

  const zoomProps = useMemo(() => ({
    image: zoomPreviewUrl,
    zoomImage: zoomPreviewUrl,
    zoomPosition: '.zoom-container'
  }), [zoomPreviewUrl]);

  const unBindZoom = () => {
    if (cz.current) {
      cz.current.destroy();
      cz.current = null;
    }
    const cb = document.querySelector('.cloudzoom-blank');
    if (cb) {
      cb.parentNode.removeChild(cb);
    }
  };

  const bindZoom = () => {
    unBindZoom();
    if (parseInt(Classes.mobilewidth, 10) < window.innerWidth) {
      if (window.jQuery(el.current).CloudZoom) {
        cz.current = window.jQuery(el.current).CloudZoom(zoomProps).data('CloudZoom');
      } else {
        setTimeout(() => {
          if (window.jQuery(el.current).CloudZoom) {
            cz.current = window.jQuery(el.current).CloudZoom(zoomProps).data('CloudZoom');
          }
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (zoomPreviewUrl) {
      bindZoom();
    }
    return () => unBindZoom();
  }, [zoomPreviewUrl]);

  return (
    <div
      style={ style }
      className={ className }
      ref={ el }
    >
      { children }
      <div className={ `zoom-container ${ Classes.zoomContainer }` } />
    </div>
  );
};

Zoom.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  zoomPreviewUrl: PropTypes.string,
  children: PropTypes.node
};

export default Zoom;
