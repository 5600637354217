import React, {
  useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import CopyLink from './copy_link';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
// <CopyLink />

const CopyForm = ({ text, title, button }) => {
  const [copied, setCopied] = useState(false);
  const copyLink = useRef();

  const doCopyLink = (e) => {
    e.preventDefault();
    copyLink.current.select();
    document.execCommand('copy');
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const buttonText = copied ? 'COPIED' : (
    <>
      <CopyLink />
      { button }
    </>
  );

  return (
    <div className={ Classes.personalLink }>
      <p>{ title }</p>
      <div className={ Classes.input }>
        <input
          type="text"
          value={ text }
          ref={ copyLink }
          readOnly
        />
        <button
          type="button"
          className={ AppClasses.button }
          onClick={ doCopyLink  }
        >
          { buttonText }
        </button>
      </div>
    </div>
  );
};

CopyForm.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired
};

export default CopyForm;
