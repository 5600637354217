import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import * as classes from './styles.module.scss';
import Likes from './likes';
import Eye from '../Viewer/eye';

const formatPrice = (v) => parseFloat(v).toFixed(2);

const buildPrices = (prices, currency) => {
  const priceRange = prices ? [formatPrice(prices[0])] : null;
  if ((prices || [])[1]) {
    priceRange.push(formatPrice(prices[1]));
  }
  return priceRange ? priceRange.map((v) => `${ currency }${ v }`).join(' - ') : null;
};

const quickShopPath = (id) => {
  const { pathname, search } = window.location;
  let updatedPathName = pathname;
  const baseLen = window.currentLocale.basename.length;
  if (pathname.substring(0, baseLen) === window.currentLocale.basename) {
    updatedPathName = pathname.substring(baseLen);
  }
  let parsedParams = queryString.parse(search);
  if (id) {
    parsedParams = { ...parsedParams, quick: id };
  } else {
    delete parsedParams.quick;
  }
  const params = queryString.stringify(parsedParams);
  return `${ updatedPathName }?${ params }`;
};
export const pathWithoutQuickShop = () => quickShopPath(null);

export const pathWithQuickShop = (id) => quickShopPath(id);

const Info = ({
  name,
  title,
  authorName,
  href,
  authorSlug,
  authorId,
  isHidden,
  prices,
  currency,
  priceRange,
  likesProps,
  skipQuickShop,
  id,
  setMlBannerWorkId,
  skipMoreLikeThis
}) => {
  const renderPrice = prices ? buildPrices(prices, currency || '$') : priceRange;
  const hiddenIcon = isHidden ? <i className="icon-eye-close" /> : null;
  const authorLink = authorSlug ? `/artists/${ authorSlug }-${ authorId }` : null;

  // const adminLink = window.hasELinks ? (
  //   <a className={ classes.admin } href={ `/admin/work_details/${ id }/edit` }>Admin</a>
  // ) : null;

  const author = authorLink ? (
    <div className={ classes.author }>
      &nbsp;
      <span>
        By&nbsp;
        <Link to={ authorLink }>
          { authorName }
        </Link>
      </span>
    </div>
  ) : (
    <div className={ classes.author }>
      &nbsp;
      <span>
        By&nbsp;
        { authorName }
      </span>
    </div>
  );

  const renderTitle = typeof title === 'string' ? (
    <Link to={ href } className={ classes.art } title={ name || title }>
      &nbsp;
      <span>
        { hiddenIcon }
        { title }
      </span>
    </Link>
  ) : title;

  const setMoreLikeThis = () => setMlBannerWorkId(id);

  const fav = likesProps && likesProps.id ? (
    <Likes { ...likesProps }>
      {
        setMlBannerWorkId && !skipMoreLikeThis && (
          <button type="button" className={ classes.moreLike } onClick={ setMoreLikeThis }>
            <Eye />
            <span>More Like This</span>
          </button>
        )
      }
    </Likes>
  ) : null;

  const qs = skipQuickShop ? null : (
    <Link to={ pathWithQuickShop(id) } className={ classes.quick }>
      Quick Shop
    </Link>
  );

  return (
    <div className={ classes.infoBase }>
      <div className={ classes.left }>
        { renderTitle }
        { author }
        <div className={ classes.prices }>
          &nbsp;
          <span>
            { renderPrice }
          </span>
        </div>
      </div>
      <div className={ classes.share }>
        { fav }
      </div>
      { qs }
    </div>
  );
};

Info.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  authorName: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  authorSlug: PropTypes.string,
  authorId: PropTypes.number,
  isHidden: PropTypes.bool,
  currency: PropTypes.string,
  prices: PropTypes.array,
  priceRange: PropTypes.string,
  likesProps: PropTypes.object,
  skipQuickShop: PropTypes.bool,
  id: PropTypes.number,
  setMlBannerWorkId: PropTypes.func,
  skipMoreLikeThis: PropTypes.bool
};

export default Info;
