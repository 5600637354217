import React, {
  useState, useEffect, useMemo, useRef
} from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Loader from 'arts/components/Loader';
import AliceCarousel from 'react-alice-carousel';
import WorkPictureImage from 'wb/components/work/work_picture_image';
import { pathWithQuickShop } from 'arts/components/WorkListItem/info';
import { getWallShufflerMatches } from '../../config/api';
import { scaledPreviewDmentions } from '../../../shared/preview_const';
import Bag from '../../assets/bag';
import * as Classes from './styles.module.scss';
import Dots from './dots';
import { trackEvent } from '../../config/analytics';

const WallPictureShuffler = ({
  frameOption, dpi,
  workId, workSizeId, frameId, title,
  calculatePosition, className, x, y,
  overrideWallPicture, wallPictureId, paperId,
  wallId
}) => {
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [alternativeWorkPictures, setAlternativeWorkPictures] = useState([{
    title,
    frameOption,
    workId,
    workSizeId,
    frameId,
    paperId
  }]);

  const carousel = useRef(null);

  const adjustedActiveIndex = useMemo(() => {
    if (activeIndex < 0) {
      return activeIndex + alternativeWorkPictures.length;
    }
    if (activeIndex >= alternativeWorkPictures.length) {
      return activeIndex - alternativeWorkPictures.length;
    }
    return activeIndex;
  }, [activeIndex, alternativeWorkPictures.length]);

  useEffect(() => {
    getWallShufflerMatches(
      workSizeId, frameId
    ).then((wps) => {
      setAlternativeWorkPictures(s => [...s, ...wps]);
      setLoading(false);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    overrideWallPicture(wallPictureId, alternativeWorkPictures[adjustedActiveIndex]);
    if (workId !== alternativeWorkPictures[adjustedActiveIndex].workId) {
      trackEvent('wall shuffle replace', {
        'wall id': wallId,
        'wall picture id': wallPictureId,
        from: workId,
        to: alternativeWorkPictures[adjustedActiveIndex].workId
      });
    }
  }, [adjustedActiveIndex]);

  const setNewItemPosition = (e) => setActiveIndex(e.item);

  const prevItem = (e) => carousel?.current?.slidePrev(e);
  const nextItem = (e) => carousel?.current?.slideNext(e);

  const toolbar = useMemo(() => {
    if (loading) {
      return (<Loader style={ { left: 2, position: 'absolute' } } />);
    }
    const wId = alternativeWorkPictures[adjustedActiveIndex].workId;
    return (
      <>
        <Link
          to={ pathWithQuickShop(wId) }
          className={ classnames(Classes.shuffleButton, Classes.first) }
        >
          <Bag style={ { stroke: '#fff' } } />
        </Link>
        <div className={ Classes.divider } />
        <button type="button" className={ Classes.shuffleButton } onClick={ prevItem } label="Prev">
          <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1L1.5 5.62857L6 10" stroke="white" strokeLinecap="round" />
          </svg>
        </button>
        <div className={ Classes.divider } />
        <button
          type="button"
          className={ classnames(Classes.shuffleButton, Classes.last) }
          onClick={ nextItem }
          label="Next"
        >
          <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 10L5 5.37143L0.499998 1" stroke="white" strokeLinecap="round" />
          </svg>
        </button>
      </>
    );
  }, [loading, adjustedActiveIndex, workId]);

  const { previewWidth, previewHeight } = frameOption;
  const { width, height } = scaledPreviewDmentions(previewWidth, previewHeight, dpi);
  const { left, top } = calculatePosition({ x, y });

  return (
    <div
      className={ className }
      style={
        {
          left,
          top,
          width,
          height
        }
      }
    >
      <div className={
        classnames(Classes.itemTools, Classes.mobileHidden, {
          [Classes.withArrows]: !loading
        })
        }
      >
        { toolbar }
      </div>
      <AliceCarousel
        animationDuration={ 400 }
        infinite
        autoHeight
        mouseTracking
        mouseDragEnabled
        responsive={ { 0: { items: 1 } } }
        ssrSilentMode
        disableButtonsControls
        disableDotsControls
        onSlideChanged={ setNewItemPosition }
        ref={ carousel }
      >
        {
          alternativeWorkPictures.map(
            (
              {
                title: altTitle,
                frameOption: {
                  zoomPreviewUrl,
                  previewHeight: altPreviewHeight,
                  previewWidth: altPreviewWidth
                }
              },
              index
            ) => (
              <WorkPictureImage
                key={ index }
                dpi={ dpi }
                title={ altTitle }
                previewWidth={ altPreviewWidth }
                previewHeight={ altPreviewHeight }
                zoomPreviewUrl={ zoomPreviewUrl }
              />
            )
          )
        }
      </AliceCarousel>
      <Dots
        count={ alternativeWorkPictures.length }
        activeIndex={ adjustedActiveIndex }
      />
    </div>
  );
};

WallPictureShuffler.propTypes = {
  frameOption: PropTypes.object.isRequired,
  dpi: PropTypes.number.isRequired,
  workSizeId: PropTypes.number.isRequired,
  workId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  calculatePosition: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  className: PropTypes.string,
  frameId: PropTypes.number,
  overrideWallPicture: PropTypes.func.isRequired,
  wallPictureId: PropTypes.number.isRequired,
  paperId: PropTypes.number.isRequired,
  wallId: PropTypes.number.isRequired
};

export default WallPictureShuffler;
