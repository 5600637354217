import React from 'react';
import PropTypes from 'prop-types';
import * as classes from './classes.module.scss';

const Error = ({ error }) => (
  <p className={ classes.error }>
    { error }
  </p>
);

Error.propTypes = {
  error: PropTypes.string.isRequired
};

export default Error;
