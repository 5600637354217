/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Radio from 'arts/components/Radio';
import * as Classes from './styles.module.scss';

const SelectPlexiGlass = ({ nonGlare, setNonGlare }) => {
  const onChange = (e) => setNonGlare(e.target.value === 'nonGlare');

  return (
    <form className={ classnames(Classes.mounts, Classes.plexi) }>
      <label htmlFor="std_plexi">
        <div className={ Classes.mountOption }>
          <Radio
            type="radio"
            value="standard"
            checked={ !nonGlare }
            onChange={ onChange }
            name="nonGlare"
            id="std_plexi"
          />
          <div className={ Classes.mount }>
            Standard
          </div>
        </div>
      </label>
      <label htmlFor="non_glare_plexi">
        <div className={ Classes.mountOption }>
          <Radio
            type="radio"
            value="nonGlare"
            checked={ nonGlare }
            onChange={ onChange }
            name="nonGlare"
            id="non_glare_plexi"
          />
          <div className={ Classes.mount }>
            Non-glare
          </div>
        </div>
      </label>
    </form>
  );
};

SelectPlexiGlass.propTypes = {
  nonGlare: PropTypes.bool,
  setNonGlare: PropTypes.func.isRequired
};

export default SelectPlexiGlass;
