import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import queryString from 'query-string';
import * as Classes from './styles.module.scss';
import pageImage from './page.png';
import thumbnailImage from './thumbnail.png';

export const THUMB = 'thumb';
export const PAGE = 'page';
export const DEFAULT_GRID = THUMB;
const THUMBNAIL_VIEW = 'Thumbnail View';
const PAGE_VIEW = 'Page View';

const GridSelection = ({ params, path, grid }) => {
  const tLink = `${ path }?${ queryString.stringify({ ...params, as: THUMB }) }`;
  const pLink = `${ path }?${ queryString.stringify({ ...params, as: PAGE }) }`;

  return (
    <div className={ Classes.selectGrid }>
      <Link
        to={ tLink }
        className={ classnames(Classes.gridSlection, { [Classes.gridType]: grid === THUMB }) }
      >
        <img src={ thumbnailImage } alt={ THUMBNAIL_VIEW } />
        { THUMBNAIL_VIEW }
      </Link>
      <Link
        to={ pLink }
        className={ classnames(Classes.gridSlection, { [Classes.gridType]: grid === PAGE }) }
      >
        <img src={ pageImage } alt={ PAGE_VIEW } />
        { PAGE_VIEW }
      </Link>
    </div>
  );
};

GridSelection.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  grid: PropTypes.string.isRequired
};

export default GridSelection;
