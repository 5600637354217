import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';
import { renderItemName, renderPrice, renderImage } from './art_item';
import RemoveCartItem from './remove_cart_item';

const OriginalArtItem = ({
  name,
  path,
  by,
  byPath,
  imageUrl,
  price,
  salesPrice,

  id,
  editable,
  removeCartItem,
  isAvailable,
  realLinks,
  locale
}) => (
  <div className={ classnames(Classes.item, Classes.classic) }>
    { renderImage(realLinks, path, imageUrl, name) }
    <div className={ Classes.info }>
      { renderItemName(realLinks, path, name, byPath, by) }
      { renderPrice('Price', price, salesPrice, locale) }
      <p>
        Original Art
      </p>
      {
        isAvailable ? null : (
          <div className={ Classes.error }>
            This item is no longer available
          </div>
        )
      }
    </div>
    { editable ? <RemoveCartItem removeCartItem={ removeCartItem } id={ id } /> : null }
  </div>
);

OriginalArtItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  by: PropTypes.string.isRequired,
  byPath: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  salesPrice: PropTypes.string,
  id: PropTypes.number.isRequired,
  editable: PropTypes.bool,
  removeCartItem: PropTypes.func,
  isAvailable: PropTypes.bool.isRequired,
  realLinks: PropTypes.bool,
  locale: PropTypes.string.isRequired
};

export default OriginalArtItem;
