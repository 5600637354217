import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { loadPageDescriptionApi } from 'arts/config/api';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';

const CategoryDescription = ({ description, skipShowMore, loadadble }) => {
  const [showMore, setShowMore] = useState(!!skipShowMore);
  const [renderDescription, setRenderDescription] = useState(description);

  useEffect(() => {
    setRenderDescription(description);
  }, [description]);

  useEffect(() => {
    if (!description && loadadble) {
      loadPageDescriptionApi().then(res => setRenderDescription(res.description)).catch(() => {});
    }
  }, [loadadble, description]);

  const button = useMemo(() => {
    if (skipShowMore) return null;
    if (showMore) {
      return (
        <button type="button" className={ Classes.showMore } onClick={ () => setShowMore(false) }>Show Less...</button>
      );
    }
    return (
      <button type="button" className={ Classes.showMore } onClick={ () => setShowMore(true) }>
        Learn More...
      </button>
    );
  }, [showMore, skipShowMore]);

  if (!renderDescription) return null;

  return (
    <div className={ AppClasses.innerContainer20 }>
      <div className={ classnames(Classes.description, { [Classes.showLess]: !showMore }) }>
        <ReactMarkdown>
          { renderDescription }
        </ReactMarkdown>
        { button }
      </div>
    </div>
  );
};

CategoryDescription.propTypes = {
  description: PropTypes.string,
  skipShowMore: PropTypes.bool,
  loadadble: PropTypes.bool
};

export default CategoryDescription;
