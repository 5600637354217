/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Work from 'wb/components/work/work';
import { pathWithQuickShop } from 'arts/components/WorkListItem/info';
import * as Classes from './styles.module.scss';
import WallPictureShuffler from './wall_picture_shuffler';
import Bag from '../../assets/bag';
import { wallPictureToPreview } from '../../config/lib';
import { useWindowSize } from '../visual_search/use_window_size';

const { mobilewidth } = Classes;

const workPictureToWorkProps = (wp, index) => {
  const {
    workId, x, y, title, workSizeId, frameId, landscape, workSizes
  } = wp;
  return {
    workId,
    x,
    y,
    title,
    workSizeId,
    frameId,
    landscape,
    workSizes,
    index,
    dragging: false,
    loadWorkDetails: null,
    moveWorkTo: null,
    removeFromCanvas: null,
    onClick: null
  };
};

const WallPicturePreview = ({
  wp, isSkipped, dpi, previewX, previewY, index, showPlus, shuffleMode, overrideWallPicture, wallId
}) => {
  const calculatePosition = ({ x, y }) => ({
    left: (x - previewX) * dpi,
    top: (y - previewY) * dpi
  });

  const workProps = workPictureToWorkProps(wp, index);
  if (!workProps.workSizeId) {
    return null;
  }

  const { width } = useWindowSize();

  const tools = useMemo(() => {
    if (!showPlus) { return null; }
    return (
      <div className={ classnames(Classes.shuffleButton, Classes.mobileHidden, Classes.itemTools) }>
        <Bag />
      </div>
    );
  }, [showPlus, wp]);

  if (shuffleMode) {
    const frameOption = (((wp.workSizes || [])[0] || {}).options || [])[0];
    if (!frameOption) {
      return null;
    }
    return (
      <WallPictureShuffler
        wallId={ wallId }
        x={ wp.x }
        y={ wp.y }
        { ...wallPictureToPreview(wp) }
        dpi={ dpi }
        calculatePosition={ calculatePosition }
        overrideWallPicture={ overrideWallPicture }
        className={
          classnames(
            Classes.shuffler,
            Classes.workPosition, {
              [Classes.unselected]: isSkipped && !shuffleMode
            }
          )
        }
      />
    );
  }

  if (width < parseInt(mobilewidth, 10)) {
    return (
      <div
        className={
          classnames(
            Classes.workPosition, {
              [Classes.unselected]: isSkipped && !shuffleMode
            }
          )
        }
        style={ calculatePosition(wp) }
      >
        { tools }
        <Work
          dpi={ dpi }
          { ...workProps }
          readOnly
        />
      </div>
    );
  }

  return (
    <Link
      to={ pathWithQuickShop(wp.workId) }
      className={
        classnames(
          Classes.workPosition, {
            [Classes.unselected]: isSkipped && !shuffleMode
          }
        )
      }
      style={ calculatePosition(wp) }
    >
      { tools }
      <Work
        dpi={ dpi }
        { ...workProps }
        readOnly
      />
    </Link>
  );
};

WallPicturePreview.propTypes = {
  wp: PropTypes.object.isRequired,
  isSkipped: PropTypes.bool,
  dpi: PropTypes.number.isRequired,
  previewX: PropTypes.number.isRequired,
  previewY: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  showPlus: PropTypes.bool.isRequired,
  shuffleMode: PropTypes.bool,
  overrideWallPicture: PropTypes.func,
  wallId: PropTypes.number.isRequired
};

export default WallPicturePreview;
