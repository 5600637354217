import React from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import {
  frameShape
} from 'arts/reducers/work';

import * as Classes from './styles.module.scss';

const Frame = ({
  data,
  onSelect,
  handleDragStart,
  selected
}) => {
  const onClick = (e) => {
    e.preventDefault();
    onSelect(data);
  };

  const classname = classnames(
    Classes.frame, {
      [Classes.active]: selected
    }
  );

  return (
    <a
      href="#frame"
      role="button"
      onClick={ onClick }
      className={ classname }
      title={ data.frameName }
      onDragStart={ handleDragStart }
      onDragEnd={ handleDragStart }
    >
      <div className={ Classes.frameHolder }>
        <img src={ data.frameThumbUrl } alt={ data.frameName } />
      </div>
    </a>
  );
};

Frame.propTypes = {
  data: frameShape.isRequired,
  onSelect: T.func.isRequired,
  handleDragStart: T.func.isRequired,
  selected: T.bool
};

export default Frame;
