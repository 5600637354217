import React from 'react';
import * as OriginalArtClasses from '../original_arts/styles.module.scss';

export default () => (
  <div className={ OriginalArtClasses.banner }>
    <div>
      <img src="https://assets.artfullywalls.com/assets/tapestries/ts2.jpg" alt="Original Art" />
    </div>
    <h2 className={ OriginalArtClasses.happy }>
      <div>
        Explore our carefully curated collection of tapestries,
        designed to bring a captivating visual experience to any space.
        Each piece adds rich color and dimension, enhancing the warmth and
        inviting atmosphere of your room. Crafted on lightweight durable
        fiber paper, our tapestries are paired with elegant wooden hanging rods
        for effortless installation, making it easy to display them in any setting.
      </div>
    </h2>
  </div>
);
