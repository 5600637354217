import React from 'react';
import T from 'prop-types';
import * as Classes from './styles.module.scss';

const Content = ({ children, ...rest }) => (
  <div className={ Classes.contentDefault } { ...rest }>
    { children }
  </div>
);

Content.propTypes = {
  children: T.node
};

export default Content;
