import React, { useEffect, useState } from 'react';
import Loader from 'arts/components/Loader';
import classnames from 'classnames';
import { request, toCamelCase } from 'arts/../shared/request';
import GTMPageView from '../Application/data_layer';
import { trackEvent } from '../../config/analytics';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from '../collections/styles.module.scss';
import Collection from '../collections/collection';

export const POPULAR_SEARCH_CATEGORIES = 'popular search categories';

const PopularSearchCategories = () => {
  const [popularSearchCategories, setPopularSearchCategories] = useState(null);

  useEffect(() => {
    if (window.popularSearchCategories) {
      setPopularSearchCategories(toCamelCase(window.popularSearchCategories));
    } else {
      request('/api/popular-search-categories')
        .then((data) => {
          setPopularSearchCategories(toCamelCase(data));
        }).catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (popularSearchCategories && popularSearchCategories.length) {
      trackEvent(POPULAR_SEARCH_CATEGORIES, { 'items count': popularSearchCategories.length });
    }
  }, [popularSearchCategories && popularSearchCategories.length]);

  const collection = !popularSearchCategories ? (
    <Loader text="Loading, hold on for a sec..." />
  ) : (
    <>
      <h1>Categories</h1>
      <ul className={ classnames(Classes.grid, AppClasses.gridContainer2inRow) }>
        {
          popularSearchCategories.map((i) => (
            <Collection
              key={ i.id }
              name={ i.title }
              collectionHref={ `/popular-search-categories/${ i.slug }` }
              description={ i.subTitle }
              topMenuImage={ i.imageHref }
              actionLabel="See More"
              useRealLink
            />
          ))
        }
      </ul>
    </>
  );

  return (
    <div className={ AppClasses.container20 }>
      { popularSearchCategories ? <GTMPageView pageType="category" section={ POPULAR_SEARCH_CATEGORIES } /> : null }
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        { collection }
      </div>
    </div>

  );
};

export default PopularSearchCategories;
