/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Classes from '../checkout/styles.module.scss';
import RemoveGiftCard from './remove_gift_card';
import AddGiftCard from './add_gift_card';

const GiftCards = ({
  giftCardUsages,
  error,
  number,
  code
}) => {
  const [showNew, setShowNew] = useState(!!error);

  const removeCards = (giftCardUsages || []).map((c) => (
    <RemoveGiftCard { ...c } key={ c.id } />
  ));

  const onAdd = () => setShowNew(true);

  const form = showNew ? (
    <AddGiftCard code={ code } number={ number } error={ error } />
  ) : (
    <button type="button" className={ Classes.actionLink } onClick={ onAdd }>
      Add a Gift Card
    </button>
  );

  return (
    <>
      <div className={ Classes.content }>
        { removeCards }
      </div>
      <div className={ Classes.content }>
        { form }
      </div>
    </>
  );
};

GiftCards.propTypes = {
  giftCardUsages: PropTypes.array,
  error: PropTypes.string,
  number: PropTypes.string,
  code: PropTypes.string
};

export default GiftCards;
