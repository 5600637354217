import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';

const renderImage = (imageUrl) => (imageUrl ? (
  <div
    className={ Classes.img }
    style={ { backgroundImage: `url("${ imageUrl }")` } }
  />
) : null);

export const ArtistSkeleton = () => (
  <li className={ AppClasses.skeleton }>
    <div className={ Classes.img } />
    <div className={ Classes.name }>
      &nbsp;
    </div>
  </li>
);

const Artist = ({
  name, imageUrl, id, slug
}) => (
  <li>
    <Link
      title={ name }
      to={ `/artists/${ id }/${ slug }` }
    >
      { renderImage(imageUrl) }
      <div className={ Classes.name }>
        { name }
      </div>
    </Link>
  </li>
);

Artist.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};

export default Artist;
