import React from 'react';
import PropTypes from 'prop-types';
import PinIt from 'arts/components/Social/pin_it';
import Favorite from '../favorite';
import * as Classes from './styles.module.scss';

const Share = ({
  id, href, description, imageUrl, noFavorites
}) => {
  const pinit = imageUrl ? (
    <PinIt
      href={ href }
      description={ description }
      imageUrl={ imageUrl }
    />
  ) : null;

  const renderFavorite = noFavorites ? null : (
    <Favorite id={ id } type="Wall" className={ Classes.favorite } />
  );

  return (
    <>
      { pinit }
      { renderFavorite }
    </>
  );
};

Share.propTypes = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  noFavorites: PropTypes.bool.isRequired
};

export default Share;
