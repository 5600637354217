import React, { PureComponent } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import ZoomModal from '../Viewer/zoom_modal';

export default class ControlsProductImage extends PureComponent {
  static propTypes = {
    zoomPreviewUrl: T.string
  };

  constructor(props)  {
    super(props);
    this.zoomIn = this.zoomIn.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = { modal: false };
  }

  zoomIn() {
    this.setState({ modal: true });
  }

  hideModal() {
    this.setState({ modal: false });
  }

  renderModal() {
    if (!this.state.modal) {
      return null;
    }
    return (
      <ZoomModal
        img={ this.props.zoomPreviewUrl }
        onHide={ this.hideModal }
        withFrame={ false }
      />
    );
  }

  renderZoomIcon() {
    if (!this.props.zoomPreviewUrl) {
      return null;
    }
    return (
      <div className={ classnames(Classes.icon, AppClasses.zoomInIcon) } onClick={ this.zoomIn }>
        <i className={ `icon-zoom-in ${ Classes.zoomIcon }` } />
      </div>
    );
  }

  render() {
    return (
      <div className={ Classes.icons }>
        { this.renderZoomIcon() }
        { this.renderModal() }
      </div>
    );
  }
}
