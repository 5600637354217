import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';
import {
  linkTo, renderPrice, renderValue, renderImage
} from './art_item';
import RemoveCartItem from './remove_cart_item';

const PATH = '/gift_cards';

const GiftCardItem = ({
  price,
  salesPrice,
  giftRecipientName,
  giftRecipientEmail,
  giftMessage,
  giftDeliveryDate,
  imageUrl,

  id,
  editable,
  removeCartItem,

  realLinks,
  locale
}) => (
  <div className={ classnames(Classes.item, Classes.classic) }>
    { renderImage(realLinks, PATH, imageUrl, giftRecipientName) }
    <div className={ Classes.info }>
      <h4>
        {
          linkTo(realLinks, PATH, (
            <>
              Gift Cart for&nbsp;
              { giftRecipientName }
            </>
          ))
        }
      </h4>
      <div className={ Classes.by }>
        { giftRecipientEmail }
      </div>
      <div className={ Classes.by }>
        { giftMessage }
      </div>
      { renderValue('Delivery Date:', giftDeliveryDate) }
      { renderPrice('Price', price, salesPrice, locale) }
    </div>
    { editable ? <RemoveCartItem removeCartItem={ removeCartItem } id={ id } /> : null }
  </div>
);

GiftCardItem.propTypes = {
  price: PropTypes.string.isRequired,
  salesPrice: PropTypes.string,
  giftRecipientName: PropTypes.string.isRequired,
  giftRecipientEmail: PropTypes.string.isRequired,
  giftMessage: PropTypes.string.isRequired,
  giftDeliveryDate: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  editable: PropTypes.bool,
  removeCartItem: PropTypes.func,
  realLinks: PropTypes.bool,
  locale: PropTypes.string.isRequired
};

export default GiftCardItem;
