import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HeartE from './heart_e';
import HeartF from './heart_f';
import * as classes from './styles.module.scss';

const Favorite = ({
  id,
  type,
  isAdded,
  add,
  remove,
  load,
  loading,
  loaded,
  className
}) => {
  useEffect(() => {
    if (!loaded && !loading) {
      load();
    }
  }, []);

  if (!window.currentUser) {
    const onSignIn = () => {
      const event = new CustomEvent('LoginRequiredFavroutes');
      document.dispatchEvent(event);
    };

    return (
      <button className={ classnames(classes.button, className) } type="button" onClick={ onSignIn } aria-label="Add to Favorites">
        <HeartE className={ classes.off } />
      </button>
    );
  }

  if (!loaded) {
    return null;
  }

  const onClick = () => {
    if (isAdded) {
      remove(id, type);
    } else {
      add(id, type);
    }
  };

  const icon = isAdded ? <HeartF className={ classes.on } /> : <HeartE className={ classes.off } />;

  return (
    <button
      className={ classnames(classes.button, className) }
      type="button"
      onClick={ onClick }
      alt="Add to Favorites"
    >
      { icon }
    </button>
  );
};

Favorite.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,

  isAdded: PropTypes.bool,

  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,

  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,

  className: PropTypes.string
};

export default Favorite;
