// extracted by mini-css-extract-plugin
var _1 = "dI48nETOdZ961AsiUHaO";
var _2 = "CpVQQkN4nGg4bHOh6fk7";
var _3 = "ptXnPqBjjVBAnyH1qyQl";
var _4 = "wPpU2fNhvYrTQcDoJecQ";
var _5 = "gulWN7_W7rNkopOjhzrq";
var _6 = "Ce4jXgoMMCILOoLrW5KU";
var _7 = "rvzGUpninXrZjxqxR6eD";
var _8 = "s6eAQTa1sKVGrln8rDAg";
var _9 = "FaBVB2eI1Pap0kUpPiuR";
export { _1 as "artistWorks", _2 as "author", _3 as "heading", _4 as "noDiscount", _5 as "original", _6 as "previews", _7 as "prints", _8 as "text", _9 as "zoom" }
