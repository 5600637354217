import React from 'react';
import T from 'prop-types';
import * as Classes from './styles.module.scss';

const Loader = ({ text, style }) => (
  <div className={ Classes.root } style={ style }>
    <i className="icon-spinner icon-spin" />
    <span>
      { text}
    </span>
  </div>
);

Loader.propTypes = {
  text: T.string,
  /* eslint-disable react/forbid-prop-types */
  style: T.any
  /* eslint-enable react/forbid-prop-types */
};

export default Loader;
