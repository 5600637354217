import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'arts/config/constants';
import Expandable from '../expandable';
import * as Classes from './styles.module.scss';

const AboutTheArt = ({
  description, printedOn, limitedEdition
}) => (
  <Expandable
    className={ Classes.aboutTheArt }
    title="About The Art"
    eKey={ Constants.Expandable.KEYS.about }
  >
    <div>
      <p>{ description }</p>
      <p>
        Printed on&nbsp;
        { printedOn }
        { limitedEdition ? '' : ' (Open Edition)' }
      </p>
    </div>
  </Expandable>
);

AboutTheArt.propTypes = {
  description: PropTypes.string,
  printedOn: PropTypes.string,
  limitedEdition: PropTypes.bool
};

export default AboutTheArt;
