import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as Classes from './styles.module.scss';

const Header = ({ children, onCancel, cancelUrl }) => {
  const button = onCancel ? (
    <button
      type="button"
      className={ Classes.cancel }
      onClick={ onCancel }
    >
      &times;
    </button>
  ) : null;

  const link = cancelUrl ? (
    <Link to={ cancelUrl } className={ Classes.cancel }>
      &times;
    </Link>
  ) : null;

  return (
    <div className={ Classes.header }>
      { children }
      <div className={ Classes.placeholder } />
      { button }
      { link }
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func,
  cancelUrl: PropTypes.string
};

export default Header;
