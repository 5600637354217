import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import * as Classes from '../checkout/styles.module.scss';
import EditCreditCard from './edit_credit_card';

const STATUS = {
  being_charged: 'Your order is being placed, please hold on for a sec or two...',
  paid_0: 'Your order is being placed, please hold on for a sec or two...',
  paid: 'Your credit card has been charged and order is placed. Please await your final order confirmation...'
};

const renderStatus = (paymentStatus, virtualOrder) => {
  let content = null;
  if (paymentStatus === 'paid') {
    setTimeout(() => {
      window.location.reload();
    }, 0);
    content = virtualOrder ? STATUS.paid_0 : STATUS.paid;
  } else {
    content = STATUS[paymentStatus];
  }
  return (
    <span>
      <Loader active inline size="tiny" />
      &nbsp;
      { content }
    </span>
  );
};

const PlacedOrder = ({
  id,
  currentPaymentStatus,
  paymentStatus,
  virtualOrder
}) => (
  <div className={ Classes.content }>
    {
      currentPaymentStatus === 'payment_status' ? renderStatus(paymentStatus, virtualOrder) : (
        <EditCreditCard
          failed={ currentPaymentStatus === 'failed' }
          id={ id }
        />
      )
    }
  </div>
);

PlacedOrder.propTypes = {
  id: PropTypes.string,
  currentPaymentStatus: PropTypes.string,
  paymentStatus: PropTypes.string,
  virtualOrder: PropTypes.bool
};

export default PlacedOrder;
