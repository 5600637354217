import React from 'react';
import classnames from 'classnames';
import T from 'prop-types';
import * as Classes from './styles.module.scss';

const Entry = ({
  url, index, selectedIndex, onClick, className
}) => {
  const klass = selectedIndex === index ? Classes.selected : '';
  const onSelect = () => onClick(index);
  return (
    <div
      className={ classnames(Classes.entry, klass, className) }
      onClick={ onSelect }
    >
      <img src={ url } alt="" />
    </div>
  );
};

Entry.propTypes = {
  url:  T.string.isRequired,
  index: T.number.isRequired,
  selectedIndex: T.number.isRequired,
  onClick: T.func.isRequired,
  className: T.string
};

export default Entry;
