import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import numeral from 'arts/config/numeral';
import { FORMAT } from '../checkout_summary/checkout_summary';
import { renderPrice, renderValue } from './art_item';
import * as Classes from './styles.module.scss';

const DiscountItem = ({
  discountPercent,
  discountText,
  discountText2,
  savedPrice,
  // wall discount
  name,
  path,
  previousPrice,
  newPrice,
  locale
}) => {
  if (locale) { numeral.locale(locale); }
  const extra1 = discountText2 ? (
    <small>
      { discountText2 }
    </small>
  ) : null;

  const extra2 = name ? (
    <>
      <p>
        <Link to={ path }>
          { name }
        </Link>
        &nbsp;
      </p>
      { renderPrice('', previousPrice, newPrice) }
    </>
  ) : null;

  return (
    <div className={ classnames(Classes.item, Classes.discount, Classes.classic) }>
      <div className={ Classes.info }>
        { extra2 }
        { renderValue('You saved:', numeral(savedPrice).format(FORMAT)) }
      </div>
      <h4>
        { discountPercent ? `${ discountPercent }% ` : null }
        { discountText }
        { extra1 }
      </h4>
    </div>
  );
};

DiscountItem.propTypes = {
  id: PropTypes.number.isRequired,
  discountPercent: PropTypes.number,
  discountText: PropTypes.string.isRequired,
  discountText2: PropTypes.string,
  savedPrice: PropTypes.string,
  // wall discount
  name: PropTypes.string,
  path: PropTypes.string,
  previousPrice: PropTypes.string,
  newPrice: PropTypes.string,
  locale: PropTypes.string.isRequired
};

export default DiscountItem;
