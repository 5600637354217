import React, { PureComponent } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import numeral from 'arts/config/numeral';
import balanceInquiryImage from 'arts/assets/balance_inquiry_image.png';
import * as AppClasses from '../../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import GTMPageView from '../../Application/data_layer';
import { FORMAT } from '../../checkout_summary/checkout_summary';

const TITLE = 'Balance Inquiry';

class GiftCardBalance extends PureComponent {
  static propTypes = {
    error: T.string,
    loading: T.bool,
    loaded: T.bool,
    giftCardNumber: T.string,
    balance: T.string,
    locale: T.string,
    showForm: T.bool,
    fetchGiftCardBalance: T.func,
    clearGiftCardBalance: T.func
  };

  constructor() {
    super();

    this.state = {
      giftCardNumber: '',
      giftCardNumberError: '',
      pin: '',
      pinError: ''
    };
    this.onChangeGiftCardNumber = this.onChangeGiftCardNumber.bind(this);
    this.onChangePin = this.onChangePin.bind(this);
    this.getBalance = this.getBalance.bind(this);
    this.checkAnotherCard = this.checkAnotherCard.bind(this);
  }

  onChangeGiftCardNumber() {
    this.setState({ giftCardNumber: this.giftCardNumber.value, giftCardNumberError: '' });
  }

  onChangePin() {
    this.setState({ pin: this.pin.value, pinError: '' });
  }

  getBalance() {
    const { giftCardNumber, pin } = this.state;
    const { fetchGiftCardBalance } = this.props;
    let result = true;
    if (giftCardNumber.trim() === '') {
      this.setState({ giftCardNumberError: 'This field is required' });
      result = false;
    }
    if (pin.trim() === '') {
      this.setState({ pinError: 'This field is required' });
      result = false;
    }
    if (result) {
      fetchGiftCardBalance(giftCardNumber, pin);
    }
  }

  checkAnotherCard() {
    const { clearGiftCardBalance } = this.props;
    this.setState({
      giftCardNumber: '',
      giftCardNumberError: '',
      pin: '',
      pinError: ''
    });
    clearGiftCardBalance();
  }

  renderBalance() {
    const {
      loading, balance, locale, loaded, error, giftCardNumber
    } = this.props;
    let content = null;
    if (loading) {
      content = 'Loading...';
    } else {
      numeral.locale(locale);
      content = numeral(balance).format(FORMAT);
    }
    if (loaded && error && !balance) {
      content = error;
    }

    return (
      <div className={ Classes.right }>
        <div className={ Classes.section }>
          <div className={ Classes.title }>Gift card number:</div>
          <div className={ Classes.balance }>{ giftCardNumber }</div>
        </div>
        <div className={ Classes.section }>
          <div className={ Classes.title }>Balance:</div>
          <div className={ Classes.balance }>{ content }</div>
        </div>
        <button type="button" className={ Classes.button } key="button" onClick={ this.checkAnotherCard }>
          Check another gift card
        </button>
      </div>
    );
  }

  renderForm() {
    const {
      giftCardNumber, pin, pinError, giftCardNumberError
    } = this.state;
    return (
      <div className={ Classes.right }>
        <div className={ Classes.section }>
          <div className={ Classes.title }>GIFT CARD NUMBER *</div>
          <input
            type="text"
            placeholder=""
            value={ giftCardNumber }
            onChange={ this.onChangeGiftCardNumber }
            ref={ (el) => { this.giftCardNumber = el; } }
            className={ Classes.input }
          />
          <div className={ Classes.error }>{ giftCardNumberError }</div>
        </div>
        <div className={ Classes.section }>
          <div className={ Classes.title }>PIN *</div>
          <input
            type="text"
            placeholder=""
            value={ pin }
            onChange={ this.onChangePin }
            ref={ (el) => { this.pin = el; } }
            className={ Classes.input }
          />
          <div className={ Classes.error }>{ pinError }</div>
        </div>
        <button type="button" className={ Classes.button } key="button" onClick={ this.getBalance }>
          Check Balance
        </button>
        <div className={ Classes.text }>Required *</div>
      </div>
    );
  }

  render() {
    const { showForm } = this.props;
    const context  = showForm ? this.renderForm() : this.renderBalance();
    return (
      <div className={ AppClasses.container20 }>
        <GTMPageView pageType="category" section="gift cards" />
        <div className={ classnames(AppClasses.innerContainer20, Classes.root)  }>
          <h1>{ TITLE }</h1>
          <div className={ Classes.content }>
            <div>
              <img src={ balanceInquiryImage } alt="Balance Inquiry" className={ Classes.image } />
            </div>
            { context }
          </div>
        </div>
      </div>
    );
  }
}

export default GiftCardBalance;
