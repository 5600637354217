import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as Classes from './styles.module.scss';

const AddButton = ({
  doAddToCart,
  adding,
  added
}) => {
  const onAddToCart = () => {
    if (adding) { return; }
    doAddToCart();
  };

  let text = <span>add to cart</span>;

  if (!added && adding) {
    text = (
      <div className={ Classes.bl }>
        <div className={ Classes.b1 } />
        <div className={ Classes.b2 } />
        <div className={ Classes.b3 } />
      </div>
    );
  } else if (added) {
    text = (
      <span>added</span>
    );
  }

  return (
    <button
      type="button"
      className={ classnames(Classes.button, { [Classes.adding]: adding || added }) }
      onClick={ onAddToCart }
    >
      { text }
    </button>
  );
};

AddButton.propTypes = {
  doAddToCart: PropTypes.func.isRequired,
  adding: PropTypes.bool.isRequired,
  added: PropTypes.bool.isRequired
};

export default AddButton;
