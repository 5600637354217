import React from 'react';
import classnames from 'classnames';
import * as AppClasses from '../Application/styles.module.scss';
import * as Classes from './styles.module.scss';
import QueryForm from './query_form';

const QueryOrderStatus = () => (
  <div className={ AppClasses.container20 }>
    <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
      <QueryForm />
    </div>
  </div>
);

export default QueryOrderStatus;
