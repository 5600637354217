import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loader from 'arts/components/Loader';
import BreadCrumbs from '../Work/bread_crumbs';
import * as AppClasses from '../Application/styles.module.scss';
import * as WorkClasses from '../Work/styles.module.scss';
import * as ViewerClasses from '../Viewer/styles.module.scss';
import * as Classes from './styles.module.scss';
import ZoomIn from '../Viewer/zoom_in';
import Entry from '../Viewer/entry';
import Scroll from '../Application/scroll';
import SideBar from './sidebar';
import OriginalArtSection from './original_art_section';
import ArtistTitle from '../ArtistWorks/artist_title';
import ArtistDetails from '../ArtistWorks/artist_details';
import Zoom from '../Viewer/zoom';
import GTMPageView from '../Application/data_layer';
import { onOriginalArtDetails, trackEvent } from '../../config/analytics';

const LOADER = () => <Loader text="Loading art details..." />;

const OriginalArt = ({
  id, name, loading, fetchOriginalArt, user, authorSlug,
  images, href, price, details, sections, isAvailable,
  userOriginalArts
}) => {
  const [previewIndex, setPreviewIndex] = useState(0);

  useEffect(() => {
    if (!name && !loading) {
      fetchOriginalArt(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && name && user) {
      trackEvent('original art page', {
        'original art id': id,
        'author id': user?.id,
        name,
        'author name': user?.name
      });
      onOriginalArtDetails({
        id, name, price, user: user || {}
      });
    }
  }, [id, name, user]);

  if (loading) {
    return LOADER();
  }

  if (!name) {
    return null;
  }

  const breadCrumbs = name
    ? (
      <BreadCrumbs
        originalArt
        title={ name }
        authorName={ user?.name }
        authorSlug={ authorSlug }
        authorId={ user?.id }
        id={ id }
      />
    ) : null;

  const entries = (images || []).map((i, index) => (
    <Entry
      url={ i.imageUrl }
      index={ index }
      key={ index }
      selectedIndex={ previewIndex }
      onClick={ setPreviewIndex }
    />
  ));

  const renderOriginalArts = userOriginalArts ? (
    <OriginalArtSection
      name={ user?.name }
      collection={ userOriginalArts }
      getListName="original-art"
      gtmPageType="product"
    />
  ) : LOADER();

  return (
    <>
      { id ? <GTMPageView pageType="product" section="original art" key={ id } /> : null }
      <div className={ AppClasses.innerContainerArt }>
        { breadCrumbs }
        <div className={ WorkClasses.work }>
          <div className={ ViewerClasses.viewer }>
            <div className={ ViewerClasses.thumbsMenu }>
              { entries }
            </div>
            <div className={ ViewerClasses.cKeeper }>
              <div className={ ViewerClasses.carousel }>
                <div className={ ViewerClasses.previews }>
                  <Zoom
                    klass={ ViewerClasses.otherImage }
                    zoomPreviewUrl={ images[previewIndex]?.imageUrl || '' }
                  >
                    <img src={ images[previewIndex]?.imageUrl || '' } alt={ name } />
                  </Zoom>
                </div>
                <ZoomIn
                  zoomPreviewUrl={ images[previewIndex]?.imageUrl || '' }
                  className={ Classes.zoom }
                />
              </div>
              <div className={ ViewerClasses.rollOver }>
                Roll Over to Zoom
              </div>
            </div>
          </div>
          <aside className={ WorkClasses.sizeSelection }>
            <SideBar
              id={ id }
              name={ name }
              user={ user || {} }
              authorSlug={ authorSlug }
              price={ price }
              details={ details }
              sections={ sections }
              isAvailable={ isAvailable }
              imageUrl={ images[previewIndex]?.imageUrl || '' }
              href={ href }
            />
          </aside>
        </div>
        <Scroll key={ id } />
      </div>
      { renderOriginalArts }
      <div className={ AppClasses.container20 }>
        <div className={ AppClasses.innerContainer20 }>
          <ArtistTitle
            name={ user?.name }
            avatarUrl={ user?.avatarUrl }
            authorId={ user?.id }
            authorSlug={ authorSlug }
            skipBreadcrumbs
            skipShare
          >
            <Link to={ `/artists/${ user?.id }/${ authorSlug }` } className={ Classes.prints }>
              Check out&nbsp;
              { user?.name }
              &rsquo;s prints
            </Link>
          </ArtistTitle>
          <ArtistDetails { ...(user || {}) } />
        </div>
      </div>
    </>
  );
};

OriginalArt.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  href: PropTypes.string,
  path: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: PropTypes.string.isRequired
  })),
  authorSlug: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    background: PropTypes.string,
    statement: PropTypes.string,
    exhibitions: PropTypes.array
  }),
  sections: PropTypes.string,
  details: PropTypes.string,
  price: PropTypes.string,
  isAvailable: PropTypes.bool,
  loading: PropTypes.bool,
  fetchOriginalArt: PropTypes.func.isRequired,
  userOriginalArts: PropTypes.array
};

export default OriginalArt;
