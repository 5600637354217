import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'arts/components/Loader';
import { request } from 'arts/../shared/request';
import { useInView } from 'react-intersection-observer';
import Artists from './artists';
import * as Classes from './styles.module.scss';

const TopArtists = () => {
  const [artists, setArtists] = useState(null);
  const [appeared, setAppear] = useState(false);

  useEffect(() => {
    if (appeared) {
      request('/artists')
        .then(({ artists: newArtists }) => {
          setArtists(newArtists);
        }).catch(() => {});
    }
  }, [appeared]);

  const { ref } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        setAppear(true);
      }
    }
  });

  return useMemo(() => (
    artists ? (
      <div className={ Classes.artists } ref={ ref }>
        <Artists artists={ artists } />
      </div>
    ) : (
      <div className={ Classes.loading } ref={ ref }>
        <Loader active inline text="Loading Artists..." />
      </div>
    )
  ), [artists && artists.length]);
};

export default TopArtists;
