import React, { PureComponent } from 'react';
import T from 'prop-types';
import { createPortal } from 'react-dom';
import Backdrop from './backdrop';
import Header from './header';
import Content from './content';

import * as Classes from './styles.module.scss';

class Modal extends PureComponent {
  static propTypes = {
    children: T.node,
    onCancel: T.func,
    cancelUrl: T.string,
    selector: T.string.isRequired,
    className: T.string,
    headerText: T.any,
    contentOptions: T.object
  }

  constructor(props) {
    super(props);

    this.rootElement = document.querySelector(props.selector);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.rootElement.appendChild(this.el);
  }

  componentWillUnmount() {
    this.rootElement.removeChild(this.el);
  }

  renderBody() {
    const {
      className, onCancel, children, headerText, contentOptions, cancelUrl
    } = this.props;
    const headerContent = headerText ? (
      <h1>
        { headerText }
      </h1>
    ) : null;

    return (
      <Backdrop onCancel={ onCancel }>
        <div className={ className || Classes.root }>
          <Header onCancel={ onCancel } cancelUrl={ cancelUrl }>
            { headerContent }
          </Header>
          <Content { ...(contentOptions || {})  }>
            { children }
          </Content>
        </div>
      </Backdrop>
    );
  }

  render() {
    return createPortal(
      this.renderBody(),
      this.el
    );
  }
}

export default Modal;
